import { Link } from 'react-router-dom';

const CommunityMoreThreads = ({
  type,
  communityType,
  prev,
  current,
  next,
  to
}: {
  to: string;
  type: string;
  communityType: string;
  current: string;
  prev: { title: string; sequence: number }[] | null;
  next: { title: string; sequence: number }[] | null;
}) => {
  return (
    <div className="flex flex-col gap-16 p-20 border-t-10 border-solid border-[#0A0A0A]">
      <p>
        다른 회원님들의{' '}
        <span className="font-bold">{PARSE_CATEGORY[type]}</span>{' '}
        {communityType}
      </p>
      <div className="flex flex-col gap-16 text-[14px]">
        {prev && prev.length
          ? prev.map((thread, idx) => (
              <Link to={`${to}/${thread.sequence}`} key={idx}>
                <span className="text-[#9170F2] pr-[4px]">Q.</span>{' '}
                {thread.title}
              </Link>
            ))
          : null}
        <p>
          <span className="text-[#8E899F]">
            <span className="text-[#9170F2] pr-[4px]">Q.</span> {current}
          </span>
        </p>
        {next && next.length
          ? next.map((thread, idx) => (
              <Link to={`${to}/${thread.sequence}`} key={idx}>
                <span className="text-[#9170F2] pr-[4px]">Q.</span>{' '}
                {thread.title}
              </Link>
            ))
          : null}
      </div>
    </div>
  );
};

export default CommunityMoreThreads;

const PARSE_CATEGORY: Record<string, string> = {
  KNOWHOW: '노하우',
  DAILY: '일상',
  CONCERN: '고민',
  COLUMN: '칼럼',
  METHOD: '운동 방법',
  INJURY_REHABILITATION: '부상 / 재활',
  NUTRITION_DIET: '영양 / 식단',
  LOSE_WEIGHT_BULK_UP: '다이어트 / 벌크업',
  COMPETITION_BODY_PROFILE: '대회 / 바디프로필'
};

import { useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { axios } from '#api/axios';
import CommunityTitle from 'src/refactor_components/community/threads/community-title';
import CommunityProfile from 'src/refactor_components/community/threads/community-profile';
import CommunityContents from 'src/refactor_components/community/threads/community-contents';
import InformationCommunityOptions from 'src/refactor_components/community/threads/information-community-options';
import QuestionerInfo from 'src/r_page/community/CommunityDetail/QuestionerInfo';
import QuestionComment from 'src/r_page/community/CommunityDetail/QuestionComments';
import QnaThreadNavigation, {
  UserQnaThreadNavigation
} from 'src/refactor_components/community/threads/qna-thread-navigation';
import { IHealthInfo } from '#types/account';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import QuestionBar from '../Answer/QuestionBar';
import { useUserInfo } from '#providers/UserInfo';
import { BoxIcon } from '#atoms';
import { isUser } from '#utils/isUser';
import { OpenGraph } from '@toss/react';
import CommunityMoreThreads from 'src/refactor_components/community/more-threads/community-more-threads';

interface QnaQuestionMoreType {
  prev: { title: string; sequence: number }[];
  next: { title: string; sequence: number }[];
}
import UserOnboarding from 'src/refactor_shared/ui/modal/user-onboarding';
import MetaHelmet from '#utils/MetaHelmet';
interface QnaCommunityType {
  id: string;
  writerId: string;
  category: string;
  sequence: number;
  title: string;
  content: string;
  createdAt: string;
  isBookmarked: boolean;
  isLiked: boolean;
  age: string;
  account: {
    nickname: string;
    startAthletic: string;
    sex: 'FEMALE' | 'MALE';
    regionSigu: string;
  };
  healthInfo: IHealthInfo;
  image?: string;

  isOp: boolean;
  profileImage?: string;
}

interface Answer {
  id: string;
  writerId: string;
  sequence: 8;
  sort: 7;
  name: string;
  shortIntroduction: string;
  depth: string;
  content: string;
  likeCount: number;
  createdAt: string;
  profile: string | null;
  isTutor: boolean;
  account: {
    regionSigu: string;
  };
  isLike: boolean;
  myUrl: string;
  _count: {
    likes: number;
  };
  image: {
    resizedLink: string;
  };
}

const QnaCommunityFeed = () => {
  const methods = useForm();
  const { id } = useParams();
  const { userInfo, isLogIn } = useUserInfo();

  const [isModalOpen, setIsModalOpen] = useState('');
  const { data } = useQuery({
    queryKey: ['get-qna-community-question', id],
    queryFn: async () =>
      await axios.get<QnaCommunityType>(
        `/consumer/qna/questions/detail?questionId=${id}`
      ),
    enabled: isLogIn ? !!userInfo?.id && !!id : !!id,
    select: ({ data }) => data
  });

  const { data: more } = useQuery({
    queryKey: ['get-qna-community-more-question', id],
    queryFn: async () =>
      await axios.get<QnaQuestionMoreType>(
        `/consumer/exercise/questions/${id}/adjacent`,
        {
          params: {
            category: data?.category
          }
        }
      ),
    enabled: !!id && !!data?.category,
    select: ({ data }) => data
  });

  const { data: answer } = useQuery({
    queryKey: ['get-qna-community-question-answer', id],
    queryFn: async () =>
      await axios.get<Answer[]>(`/consumer/qna/answers`, {
        params: {
          // userId: userInfo?.id ?? id,
          questionId: id,
          limit: 1000
        }
      }),
    enabled: isLogIn ? !!userInfo?.id && !!id : !!id,
    select: ({ data }) => data
  });

  const { data: hasLocationOnboard } = useQuery({
    queryKey: ['get-user-location-onboard'],
    queryFn: async () =>
      await axios.get<string>(`accounts/consumer/location-onboard`),
    select: ({ data }) => data,
    enabled: isLogIn
  });

  if (!data) return null;

  return (
    <>
      <MetaHelmet
        title={`${data.title} - 피벗 운동 질문 답변`}
        description={data.content}
        img={data.image}
      />
      <PrevHeaderBarWithTitleTemplate
        defaultBackground
        title=""
        rightContents={
          data.isOp && (
            <button onClick={() => setIsModalOpen('open')}>
              <BoxIcon
                type="dots_vertical_rounded"
                boxColor="transparent"
                size={18}
                onlyIcon={true}
              />
            </button>
          )
        }
      >
        <div className="relative">
          <FormProvider {...methods}>
            <div className="flex flex-col w-full gap-20 pb-100">
              <CommunityTitle category={data.category} title={data.title} />
              <CommunityProfile createdAt={data.createdAt}>
                <CommunityProfile.Consumer
                  profileImage={data.profileImage}
                  consumerName={data?.account?.nickname}
                  addres={data?.account?.regionSigu}
                />
              </CommunityProfile>
              <QuestionerInfo
                data={data?.healthInfo}
                age={data?.age}
                sex={data?.account?.sex}
              />
              <CommunityContents content={data.content} />
              <QuestionComment>
                {answer &&
                  answer.map(value => {
                    return (
                      <QuestionComment.Wrap key={value.id}>
                        <QuestionComment.Profile
                          profileImage={value.profile}
                          questionerName={value.name}
                          shortIntroduction={value.shortIntroduction}
                          createdAt={value.createdAt}
                          myUrl={value.myUrl}
                          isTutor={value.isTutor}
                        />
                        <QuestionComment.Content content={value.content} />
                        <QuestionBar
                          targetId={value.id}
                          isLike={value.isLike}
                        />
                      </QuestionComment.Wrap>
                    );
                  })}
              </QuestionComment>
              <CommunityMoreThreads
                type={data.category}
                communityType="질문"
                prev={more?.prev ? more?.prev : null}
                current={data.title}
                next={more?.next ? more?.next : null}
                to="/talk/qna"
              />
            </div>
            <div className="fixed bottom-0 w-full">
              {isUser() ? (
                <UserQnaThreadNavigation
                  isBookmarked={data.isBookmarked}
                  isLike={data.isLiked}
                  questionId={data.id}
                />
              ) : (
                <QnaThreadNavigation />
              )}
            </div>
            {isModalOpen && (
              <InformationCommunityOptions
                data={data}
                setIsModalOpen={setIsModalOpen}
              />
            )}
            {!hasLocationOnboard && isLogIn && <UserOnboarding />}
          </FormProvider>
        </div>
      </PrevHeaderBarWithTitleTemplate>
    </>
  );
};

export default QnaCommunityFeed;

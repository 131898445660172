import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import FibudCertificate from '#assets/svg/fibud-certificate.svg';
import { BoxIcon } from '#atoms';
import { Modal } from '#molecules';
import ArrowRight from '#assets/svg/arrow_right.svg';
import ToggleDown from '#assets/svg/toggle_down.svg';

const ReviewCard = ({
  children,
  trainerName,
  trainerUrl,
  address,
  profileImage,
  hasPermission
}: {
  children: ReactNode;
  trainerName: string;
  trainerUrl: string;
  address: string;
  profileImage: string;
  hasPermission: boolean;
}) => {
  return (
    <div className="py-28 flex flex-col gap-20">
      <div className="flex flex-col justify-center items-center p-20 gap-20 rounded-16 bg-[#24222A]">
        {children}
        <div className="flex items-center justify-between gap-20 pt-20 border-t-1 border-solid border-[#2E2B36] w-full">
          <div className="flex items-center gap-20">
            <Link to={`/expert/${trainerUrl}`}>
              <img
                src={profileImage}
                className="w-48 h-48 rounded-full bg-slate-500"
              />
            </Link>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-4 text-[#EAE8ED]">
                <Link to={`/expert/${trainerUrl}`}>{trainerName} 전문가</Link>
                {hasPermission && (
                  <img src={FibudCertificate} className="w-16 h-16" />
                )}
              </div>
              <p className="text-[#8E899F] text-[14px]">{address}</p>
            </div>
          </div>
          <img src={ArrowRight} className="w-16 h-16" />
        </div>
      </div>
    </div>
  );
};

const ReviewInformation = ({
  lectureTitle,
  rounds,
  age,
  userName,
  sex,
  rate,
  children
}: {
  lectureTitle: string;
  rounds: number;
  age: string;
  userName: string;
  sex: string;
  rate: string;
  children: ReactNode;
}) => {
  return (
    <div className="w-full flex flex-col gap-20 flex-shrink-0 snap">
      <div className="flex justify-between items-center">
        <div>
          <p>
            <span className="text-[#9170F2]">{userName}</span> 님 · {age}세{' '}
            {sex}
          </p>
          <p className="text-[#8E899F] text-[14px] flex items-center gap-8">
            <span>{lectureTitle}</span>
            <span>{rounds}회</span>
          </p>
        </div>
        <div className="flex items-center gap-2">
          <BoxIcon type="star_purple" size={17} onlyIcon />
          <span>{rate}</span>
        </div>
      </div>
      {children}
    </div>
  );
};

const ReviewInfoImage = ({
  content,
  reviewImage
}: {
  content: string;
  reviewImage: string[];
}) => {
  const [isMore, setIsMore] = useState(false);
  const [imageModalOpen, setImageModalopen] = useState({
    state: false,
    image: ''
  });
  const [showImage, setShowImage] = useState(
    reviewImage.length > 4 ? 4 : reviewImage.length
  );

  const handleShowImage = (index: number, img: string) => {
    if (showImage === reviewImage.length) {
      return setImageModalopen({ state: true, image: img });
    }

    if (index === showImage - 1) {
      return setShowImage(reviewImage.length);
    }

    setImageModalopen({ state: true, image: img });
  };

  return (
    <div className="flex flex-col gap-20">
      <p className={`${isMore ? 'h-auto' : 'line-clamp-4'}`}>{content}</p>
      <div className="w-full flex justify-end items-center">
        <button
          type="button"
          onClick={() => setIsMore(prev => !prev)}
          className="text-[#8E899F] text-[12px] flex items-center gap-4"
        >
          {isMore ? '접기' : '더보기'}
          <img
            src={ToggleDown}
            className={`${isMore ? 'rotate-180' : ''} w-8 h-8`}
          />
        </button>
      </div>
      {reviewImage.length ? (
        <div className="flex justify-between w-full gap-12 flex-wrap">
          {reviewImage.slice(0, showImage).map((img, index) => {
            return (
              <img
                key={img}
                src={img}
                alt="asdf"
                onClick={() => handleShowImage(index, img)}
                className="w-[calc(25%-12px)] aspect-square rounded-8"
              />
            );
          })}
        </div>
      ) : null}
      {imageModalOpen.state && (
        <Modal onClose={() => setImageModalopen({ state: false, image: '' })}>
          리뷰 이미지
          <img src={imageModalOpen.image} className="w-full aspect-square" />
        </Modal>
      )}
    </div>
  );
};

ReviewCard.info = ReviewInformation;
ReviewCard.image = ReviewInfoImage;

export default ReviewCard;

import request from '#api/axios';
import { useToast } from '#providers/ToastProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { ThreadType } from 'src/r_page/qna-community/Save';

export const getUserCommunity = async () => {
  const res = await request<ThreadType[]>({
    method: 'get',
    url: '/consumer/exercise/questions',
    params: {}
  });
  return res.data;
};

export const useGetUserCommunityQuery = (isQnaList: boolean) =>
  useQuery(['get-information-community-threads'], getUserCommunity, {
    enabled: !isQnaList
  });

export const getQnaCommunity = async () => {
  const res = await request<ThreadType[]>({
    method: 'get',
    url: '/consumer/qna/questions'
  });
  return res.data;
};

export const useGetQnaCommunityQuery = (isQnaList: boolean) =>
  useQuery(['get-qna-community-threads'], getQnaCommunity, {
    enabled: isQnaList
  });

export const getUserQnaCommunity = async () => {
  const res = await request<ThreadType[]>({
    method: 'get',
    url: '/consumer/qna/user/questions',
    params: {
      limit: 1000
    }
  });
  return res.data;
};

export const useGetUserQnaCommunityQuery = () =>
  useQuery(['get-qna-community-threads'], getUserQnaCommunity);

export const getUserCommunityBookmarks = async () => {
  const res = await request<ThreadType[]>({
    method: 'get',
    url: '/consumer/exercise/bookmarks'
  });
  return res.data;
};

export const useGetUserCommunityBookmarksQuery = () =>
  useQuery(['user-consumer-bookmarks'], getUserCommunityBookmarks);

export const getUserCommunityDetail = async (questionId: string) => {
  const res = await request<ThreadType>({
    method: 'get',
    url: `/consumer/exercise/questions/${questionId}`
  });
  return res.data;
};

export const useGetUserCommunityDetailQuery = (questionId: string) =>
  useQuery(['user-consumer-detail'], () => getUserCommunityDetail(questionId));

export interface PostTmmunityCommnetParams {
  questionId: string;
  writerId: string;
  content: string;
  sequence?: number;
  originalAnswerId?: string;
  receiverId?: string;
}

export const postInformationCommunityComments = async (
  questionId: string,
  param: PostTmmunityCommnetParams
) => {
  const res = await request<string>({
    method: 'post',
    url: `/consumer/exercise/${questionId}/answers`,
    data: param
  });
  return res;
};

export const usePostInformationCommentMutation = (
  questionId: string,
  param: PostTmmunityCommnetParams
) => {
  const { showToast } = useToast();
  const { reset } = useFormContext();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () =>
      await postInformationCommunityComments(questionId, param),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-information-community-thread-comment', questionId]
      });
      queryClient.invalidateQueries({
        queryKey: ['get-information-community-thread-comment-count', questionId]
      });
      return reset({ content: '' });
    },
    onError: (e: AxiosError) => {
      showToast({
        message:
          e.response?.status === 401
            ? '댓글 작성은 로그인 후 이용 가능합니다.'
            : '댓글 작성에 실패했습니다.',
        type: 'fail'
      });
    }
  });
  return mutation;
};

export const postTrainerCommunityComments = async (
  questionId: string,
  param: PostTmmunityCommnetParams
) => {
  const res = await request<string>({
    method: 'post',
    url: `/trainers/${questionId}/comments`,
    data: param
  });
  return res;
};

export const usePostTmmunityCommentMutation = (questionId: string) => {
  const { reset } = useFormContext();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (param: PostTmmunityCommnetParams) =>
      await postTrainerCommunityComments(questionId, param),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-trainer-community-thread-comment']
      });
      return reset({ content: '' });
    }
  });
  return mutation;
};

export interface PostQnaQuestionParam {
  category: string;
  title: string;
  content: string;
  imageId?: string;
  healthInfoId?: string;
  images: Array<{ id: string; uploadedLink: string }>;
  location: string;
  career: string[];
  disease: string[];
  painParts: string[];
}

export const postQnaQuestion = async (param: PostQnaQuestionParam) => {
  const res = await request<{ id: string }>({
    method: 'post',
    url: '/consumer/qna/questions',
    data: param
  });
  return res;
};

export const usePostQnaQuestionMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { showToast } = useToast();

  const mutation = useMutation({
    mutationFn: postQnaQuestion,
    onSuccess: (response: AxiosResponse) => {
      queryClient.invalidateQueries(['get-qna-community-threads']);
      showToast({
        message: '게시물을 등록했습니다.',
        type: 'success'
      });
      navigate(`/talk/qna/${response.data}`);
      return;
    },
    onError: () => {
      queryClient.invalidateQueries(['get-qna-community-threads']);
      showToast({
        message: '게시물 등록에 실패했습니다.',
        type: 'fail'
      });
      return navigate('/talk/qna');
    }
  });

  return mutation;
};

export interface PostQnaLikesParam {
  targetId: string;
  kind: string;
}
export const postQnaLikes = async (param: PostQnaLikesParam) => {
  const res = await request<string>({
    method: 'post',
    url: '/consumer/qna/likes',
    data: param
  });
  return res;
};

export const usePostQnaLikesMutaiton = () => {
  const mutation = useMutation(postQnaLikes);
  return mutation;
};

export const postExerciseLikes = async (param: PostQnaLikesParam) => {
  const res = await request<string>({
    method: 'post',
    url: '/consumer/exercise/likes',
    data: param
  });
  return res;
};

export const usePostExerciseLikesMutation = () => {
  const mutation = useMutation(postExerciseLikes);
  return mutation;
};

interface RecentQna {
  questionId: string;
  questionSequence: number;
  questionWriterId: string;
  title: string;
  content: string;
  answer: string;
  answerWriterId: string;
}

export const getRecentQna = async () => {
  const res = await request<RecentQna[]>({
    method: 'get',
    url: '/consumer/home/exercise-questions'
  });
  return res.data;
};

export const useGetRecentQnaQuery = () =>
  useQuery(['getRecentExerciseInformation'], getRecentQna, {
    suspense: true
  });

interface ExerciseInformation {
  answerCount: number;
  content: string;
  id: string;
  likeCount: number;
  sequence: number;
  title: string;
  writerId: string;
  image: string;
}

export const getPopularExerciseQuestions = async () => {
  const res = await request<ExerciseInformation[]>({
    method: 'get',
    url: '/consumer/home/like-exercise-questions'
  });
  return res.data;
};

export const useGetPopularExerciseQuestionsQuery = () =>
  useQuery(['get-popular-exercise-questions'], getPopularExerciseQuestions);

export const getWhiteListCoach = async (sort: string) => {
  const res = await request<
    {
      name: string;
      profileImage: string;
      myUrl: string;
      shortIntroduction: string;
    }[]
  >({
    method: 'get',
    url: '/consumer/home/whitelist',
    params: { sort }
  });
  return res.data;
};

export const useGetWhiteListCoach = (sort: string) =>
  useQuery({
    queryKey: ['get-white-list-coach', sort],
    queryFn: async () => await getWhiteListCoach(sort)
  });

interface PatchQnaQuestionsByIdParam {
  questionId: string;
  param: PostQnaQuestionParam;
}

export const patchQnaQuestionsById = async ({
  param,
  questionId
}: PatchQnaQuestionsByIdParam) => {
  const res = await request({
    method: 'patch',
    url: `/consumer/qna/questions/${questionId}`,
    data: param
  });
  return res.data;
};

export const usePatchQnaQuestionsByIdMutation = () => {
  const mutation = useMutation(patchQnaQuestionsById);
  return mutation;
};

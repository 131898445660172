import { useLocation, useNavigate, useParams } from 'react-router';
import { useRecoilState } from 'recoil';

import { CoachDetailApplyModal } from '#templates';
import { useGetCoachIdFromMyUrl } from '#api/my-url';
import { $modalFamily, MODAL_TYPE } from '#states/atoms/modalState';
import { useChat } from '#providers/Chat';
import { PATH } from '#const/path';
import { IGym } from '#types/gyms';

import { useTabListView } from 'src/components/molecules/TabListViewer';
import TrainerActivities from 'src/refactor_components/trainer-profile/trainer-activities';
import TrainerLecture from 'src/refactor_components/trainer-profile/trainer-lecture';
import TrainerIntro from 'src/refactor_components/trainer-profile/trainer-intro';
import TrainerQualification from 'src/refactor_components/trainer-profile/trainer-qualification';
import TrainerSchedule from 'src/refactor_components/trainer-profile/trainer-schedule';
import TrainerLocation from 'src/refactor_components/trainer-profile/trainer-location';
import RowContent from 'src/refactor_shared/ui/row-content';
import FibudCertification from 'src/refactor_components/trainer-profile/fibud-certification';

type TrainerQualificationProps = {
  degree: {
    major: string;
  }[];
  licenses: {
    name: string;
  }[];
  awards: number;
};

type LecturesDataType = {
  id: string;
  lectureType: string;
  title: string;
  body: string;
  price: number;
  rounds: number;
};

type TrainerActivitiesInformationProps = {
  title: string;
  content: string;
  sequence: number;
  _count: {
    comments: number;
    likes: number;
  };
  image: { resizedLink: string };
  id: string;
};

type TrainerHomeProps = {
  data: {
    account: {
      shortIntroduction: string;
      introduction: string;
      name: string;
      hasWriteColumnPermission: boolean;
    };
    degree: {
      name: string;
      count: number;
    };
    career: {
      name: string;
      count: number;
    };
    license: {
      name: string;
      count: number;
    };
    award: {
      name: string;
      count: number;
    };
    education: {
      name: string;
      count: number;
    };
    qualifications: TrainerQualificationProps;
    schedules: Array<object>;
    gyms: IGym[];
  };
  lecturesData: LecturesDataType[];
  informationQuestion: TrainerActivitiesInformationProps[];
  portfolio: {
    data: {
      id: number;
      title: string;
      age: number;
      sex: string;
      criteria: string;
      profileImage: string;
    }[];
  };
  qnaQuestion: {
    id: string;
    sequence: number;
    questionTitle: string;
    content: string;
  }[];
};

const TrainerHome = ({
  data,
  lecturesData,
  informationQuestion,
  portfolio,
  qnaQuestion
}: TrainerHomeProps) => {
  const noContentForQualification =
    data?.degree?.name ||
    data?.career?.name ||
    data?.license?.name ||
    data?.award?.name ||
    data?.education?.name;

  const { setCurrentTab } = useTabListView();

  const [, setApplyModal] = useRecoilState($modalFamily(MODAL_TYPE.APPLY));

  return (
    <>
      <div className="flex flex-col w-full px-20 gap-60">
        {/* <TrainerBadge badge={[]} /> */}
        {data.account?.hasWriteColumnPermission && <FibudCertification />}

        <TrainerIntro
          shortIntroduction={data.account.shortIntroduction}
          introduction={data.account.introduction}
        />
        {noContentForQualification && (
          <TrainerQualification data={data.qualifications} title="정보">
            <>
              <RowContent
                title="학력"
                content={data.degree.name}
                totalCounts={data.degree.count}
              />
              <RowContent
                title="경력"
                content={data.career.name}
                totalCounts={data.career.count}
              />
              <RowContent
                title="자격"
                content={data.license.name}
                totalCounts={data.license.count}
              />
              <RowContent
                title="수상"
                content={data.award.name}
                totalCounts={data.award.count}
              />
              <RowContent
                title="교육"
                content={data.education.name}
                totalCounts={data.education.count}
              />
              <TrainerQualification.More
                onHandleCustomClick={setCurrentTab}
                targetTab={1}
              />
            </>
          </TrainerQualification>
        )}
        {portfolio && informationQuestion ? (
          <TrainerActivities
            name={data.account.name}
            isVisible={
              informationQuestion?.length || qnaQuestion?.length ? true : false
            }
          >
            <TrainerActivities.port clinicalDataList={portfolio} />
            <TrainerActivities.info data={informationQuestion} />
            <TrainerActivities.qna data={qnaQuestion} />
          </TrainerActivities>
        ) : null}
        {lecturesData && (
          <TrainerLecture
            lecturesData={lecturesData}
            setShowModal={setApplyModal}
          />
        )}
        {data?.schedules && <TrainerSchedule data={data?.schedules} />}
        {data.gyms && <TrainerLocation recommendGyms={data.gyms} />}
      </div>
    </>
  );
};

export default TrainerHome;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  touch-action: auto;
  gap: 1.6rem;
`;

export const PortfolioItems = styled.div`
  width: 18rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  flex-shrink: 0;
`;

export const ImageBox = styled.div`
  width: 100%;
  border-radius: 0.8rem;
  display: flex;
  overflow: hidden;
  item-align: center;
  justify-content: center;
  & > img {
    width: 100%;
    height: 12rem;
  }
`;

export const Tag = styled.div`
  width: 8rem;
  display: flex;
  padding: 2.5px 7px 2.5px 5px;
  align-items: center;
  gap: 3px;
  border-radius: 22px;
  background-color: #1 f1e25;
`;

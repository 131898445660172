import { FormProvider, useForm } from 'react-hook-form';

import { PrevHeaderBarWithTitleTemplate } from '#templates';

import {
  PostQnaQuestionParam,
  usePatchQnaQuestionsByIdMutation,
  usePostQnaQuestionMutation
} from '#api/community';
import useFunnel from 'src/refactor_shared/hooks/useFunnel';

import CommunityWriteForms from 'src/refactor_components/community/write/community-write-forms';
import CommunityWriteStep2 from 'src/r_page/Information-community/Write/Step/Step2';
import WriteRegister from 'src/r_page/Information-community/Write/components/register';
import WriteImages from 'src/r_page/Information-community/Write/components/images';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useUserInfo } from '#providers/UserInfo';
import { isUser } from '#utils/isUser';
import { useToast } from '#providers/ToastProvider';
import { useQueryClient } from '@tanstack/react-query';
import CommunityOauthModal from 'src/r_page/community/Modal';
import { Modal } from '#molecules';
import { PARSE_CATEGORY } from 'src/r_page/community/ArticlePage/feed';
import {
  BottomSheetArea,
  BottomSheetWrap
} from 'src/components/organisims/ConfirmationBottomSheet/styles';

const InformationCommunityWrite = () => {
  const params = useParams();

  const { state } = useLocation();
  const { isLogIn } = useUserInfo();
  const methods = useForm<PostQnaQuestionParam>({
    defaultValues: {
      category: '',
      title: '',
      content: '',
      images: [],
      location: '',
      career: [],
      disease: [],
      painParts: []
    }
  });

  const { Funnel, Step, setCurrentFunnelStep } = useFunnel('main');

  const { mutate: postQnaQuestionsMutation, isLoading: disalbeOnLoading } =
    usePostQnaQuestionMutation();
  const { mutate: patchQnaQuestionsByIdMutation } =
    usePatchQnaQuestionsByIdMutation();

  // const postHealthInfosMutation = usePostHealthInfosMutation();
  useEffect(() => {
    if (state) {
      methods.reset({
        ...state,
        category: PARSE_CATEGORY[state.category]
      });
    }
  }, [state]);

  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const isValidLoginUser = () => {
    sessionStorage.setItem('prevState', JSON.stringify(methods.getValues()));
    sessionStorage.setItem('blockedPagePath', '/talk/qna');
    window.location.href = `${process.env.REACT_APP_API_SERVER_URL}/auth/login/kakao?state=fibud`;
  };

  const onSubmit = (data: PostQnaQuestionParam) => {
    if (!data.category) {
      showToast({ type: 'fail', message: '카테고리를 선택해주세요' });
      return;
    }
    if (!data.title) {
      showToast({ type: 'fail', message: '제목을 입력해주세요' });
      return;
    }
    if (!data.content) {
      showToast({ type: 'fail', message: '내용을 입력해주세요' });
      return;
    }

    if (!isUser()) {
      setIsOpen(true);
      return;
    }

    if (isLogIn) {
      if (params.id) {
        patchQnaQuestionsByIdMutation(
          {
            param: data,
            questionId: params.id
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([
                'get-infinite-qna-community-threads'
              ]);
              queryClient.invalidateQueries([
                'get-qna-community-question',
                params.id
              ]);
              navigate(`/talk/qna/${params.id}`);
            }
          }
        );
      } else {
        postQnaQuestionsMutation(data, {
          onSuccess: () => {
            queryClient.invalidateQueries([
              'get-infinite-qna-community-threads'
            ]);
            navigate('/talk/qna');
          }
        });
      }
    } else {
      navigate('/sign');
    }
  };

  return (
    <FormProvider {...methods}>
      <Funnel>
        <Step step="main">
          <form
            onSubmit={methods.handleSubmit(payload => {
              onSubmit(payload);
            })}
          >
            <PrevHeaderBarWithTitleTemplate
              defaultBackground
              title={`질문 ${params.id ? '수정' : '등록'}`}
              prevRoute="../."
            >
              <CommunityWriteForms>
                <CommunityWriteForms.Categories
                  registerName="category"
                  defaultValue={
                    state && state.category
                      ? PARSE_CATEGORY[state.category]
                      : PARSE_CATEGORY[methods.watch('category')]
                  }
                />
                <CommunityWriteForms.Title registerName="title" />
                <CommunityWriteForms.Content registerName="content" />
                {isLogIn && <WriteImages registerName="images" />}
                {/* <WriteLocation registerName="location" /> */}
                {/* <WriteMoveFunnel setFunnel={setCurrentFunnelStep} /> */}
                <WriteRegister
                  isPatch={params.id ? true : false}
                  disableOnLoading={disalbeOnLoading}
                />
              </CommunityWriteForms>
            </PrevHeaderBarWithTitleTemplate>
          </form>
          {isOpen && (
            <BottomSheetWrap>
              <BottomSheetArea>
                <CommunityOauthModal
                  onClose={() => setIsOpen(false)}
                  kakaoLogin={isValidLoginUser}
                />
              </BottomSheetArea>
            </BottomSheetWrap>
          )}
        </Step>
        <Step step="additional">
          <CommunityWriteStep2 setCurrentFunnelStep={setCurrentFunnelStep} />
        </Step>
      </Funnel>
    </FormProvider>
  );
};

export default InformationCommunityWrite;

import FibudCertificate from '#assets/svg/fibud-certificate.svg';

const FibudCertification = () => {
  return (
    <div className="flex flex-col gap-32 pt-20">
      <p className="text-[18px] font-bold">피벗 인증</p>
      <div className="py-12 px-16 rounded-6 border-[#24222A] border border-solid">
        <div className="flex flex-col gap-12">
          <div className="flex gap-12 items-center">
            <img src={FibudCertificate} />
            <p className="text-[#089B7A]">피벗 트레이너 튜터</p>
          </div>

          <p className="text-[#C9C7D1] text-[14px]">
            운동에 대한 높은 전문성과 풍부한 경험을 바탕으로 트레이너들을
            교육하는 트레이너들의 선생님 입니다.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FibudCertification;

import { Link } from 'react-router-dom';

import { axios } from '#api/axios';
import { useQuery } from '@tanstack/react-query';

const BookmarkInformation = ({ id }: { id?: string }) => {
  const { data: informationList } = useQuery({
    queryKey: id
      ? ['get-information-community-coach-questions', id]
      : ['get-information-community-coach-questions'],
    queryFn: async () =>
      await axios.get(
        id
          ? `/consumer/exercise/coach/questions/${id}`
          : '/consumer/exercise/coach/questions'
      ),
    select: data => data.data
  });

  return (
    <>
      {informationList &&
        informationList.map(
          (
            information: {
              title: string;
              content: string;
              id: string;
              image: { resizedLink: string };
              _count: {
                comments: number;
                likes: number;
              };
            },
            index: number
          ) => (
            <div
              key={index}
              className="flex flex-col gap-12 border-b-1 border-solid border-[#24222A]"
            >
              <Link
                to={`/talk/guide/${information.id}`}
                className="flex justify-between font-bold gap-12"
              >
                <div>
                  <p>{information.title}</p>
                  <p
                    className="text-ellipsis overflow-hidden line-clamp-2 pt-6 text-[14px] text-[#8E899F]"
                    dangerouslySetInnerHTML={{ __html: information.content }}
                  />
                </div>
                {information.image && (
                  <img
                    src={information.image.resizedLink}
                    className="w-64 h-64 rounded-8"
                  />
                )}
              </Link>
              <div className="flex justify-between items-center text-[#8E899F] py-7">
                <p className="text-center w-full">
                  댓글 {information._count.comments}
                </p>
                <p className="text-center w-full">
                  좋아요 {information._count.likes}
                </p>
              </div>
            </div>
          )
        )}
    </>
  );
};

export default BookmarkInformation;

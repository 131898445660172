// import MoreIcon from '#assets/icon/MoreIcon';
import { TRANIER_COMMUNITY_TPYE_LIST } from 'src/r_page/Trainer_Community/constant';
import * as S from './style';
import { useLocation, useNavigate } from 'react-router';

interface Props {
  tag: string;
  targetValue: string;
}

const CommunityTag = ({ tag, targetValue }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <S.Container>
      <S.Wrap>
        {TRANIER_COMMUNITY_TPYE_LIST[targetValue].map((tmmunityType, idx) => (
          <S.Item
            key={idx}
            onClick={() => {
              navigate(`${pathname}?tag=${tmmunityType}`);
            }}
            isSelected={tag === tmmunityType ? true : false}
          >
            {tmmunityType === 'ALL' ? '전체' : PARSE_CATEGORY[tmmunityType]}
          </S.Item>
        ))}
      </S.Wrap>
    </S.Container>
  );
};

export default CommunityTag;

const PARSE_CATEGORY: Record<string, string> = {
  undefined: '전체',
  ALL: '전체',
  KNOWHOW: '노하우',
  DAILY: '일상',
  CONCERN: '고민',
  COLUMN: '칼럼',
  METHOD: '운동 방법',
  INJURY_REHABILITATION: '부상 / 재활',
  NUTRITION_DIET: '영양 / 식단',
  LOSE_WEIGHT_BULK_UP: '다이어트 / 벌크업',
  COMPETITION_BODY_PROFILE: '대회 / 바디 프로필'
};

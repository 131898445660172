import { ChartBox, Container } from './styles';

interface HighlightCardProps {
  children: React.ReactNode;
}

export const HighlightCard = ({ children }: HighlightCardProps) => {
  return (
    <Container>
      <ChartBox>{children}</ChartBox>
    </Container>
  );
};

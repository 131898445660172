const useDateFormatter = (dateString: string): string => {
  const formatDateAndTime = (data: string): string => {
    const now = new Date();
    const dataDatetime = new Date(data);

    const timeDiff = now.getTime() - dataDatetime.getTime(); // 현재 시간과의 차이 (밀리초 단위)

    // 날짜 포맷 처리
    const formatDate = (): string => {
      // 1년 이상 차이 나면 yyyy-mm-dd 형식, 아니면 mm-dd 형식으로
      if (dataDatetime.getFullYear() < now.getFullYear()) {
        return dataDatetime.toISOString().split('T')[0]; // yyyy-mm-dd 형식
      } else {
        const month = String(dataDatetime.getMonth() + 1).padStart(2, '0');
        const day = String(dataDatetime.getDate()).padStart(2, '0');
        return `${month}-${day}`; // mm-dd 형식
      }
    };

    // 시간 포맷 처리
    const formatTime = (): string => {
      // 1~23시간 이내의 차이
      if (timeDiff < 86400000) {
        // 24시간 이내
        if (timeDiff < 3600000) {
          // 1시간 미만일 경우
          const minutes = Math.floor(timeDiff / 60000);
          return minutes < 0 ? '방금 전' : `${minutes} 분 전`;
        } else {
          // 1시간 이상일 경우
          const hours = Math.floor(timeDiff / 3600000);
          return `${hours} 시간 전`;
        }
      }

      // 24시간 이상의 차이 -> 날짜로 표현
      return formatDate(); // 날짜 형식으로 반환
    };

    // 시간 차이에 따라 날짜 또는 시간 형식 반환
    if (timeDiff >= 86400000) {
      return formatDate(); // 날짜 반환
    } else {
      return formatTime(); // 시간 또는 분 반환
    }
  };

  // 날짜와 시간을 포맷팅한 값 바로 반환
  return formatDateAndTime(dateString);
};

export default useDateFormatter;

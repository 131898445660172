import { getAllCoachAccounts, getAllCoachAccountsPublic } from '#api/accounts';
import { AltImage, NextArrow } from '#assets/svg';
import { Text } from '#atoms';
import useInfiniteScrollQuery from '#hooks/useInfiniteScrollQuery';
import { useIntersectionObserver } from '#hooks/useIntersectionObserver';
import useScrollRestoration from '#hooks/useScrollRestoration';
import { useUserInfo } from '#providers/UserInfo';
import {
  $coachConditionState,
  ICoachConditionState
} from '#states/atoms/coachCondition';
import { $selectedCoachTagState } from '#states/atoms/tagState';
import { CoachAccount } from '#types/account';
import { IGetAllCoachAccountsProps, IResponse } from '#types/api';
import sessionStorageManager from '#utils/sessionStorageManager';
import { getCoachCareer } from '#utils/timeFormatter';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import LoadingSpinner from 'src/components/atoms/LoadingSpinner';
import { ExpertFieldBox } from 'src/components/molecules/ExpertFieldBox';
import { ExpertNameBox } from 'src/components/molecules/ExpertNameBox';
import { ExpertTagsBox } from 'src/components/molecules/ExpertTagsBox';
import { PER_PAGE_NUMBER } from 'src/shared/constant';
import { ExpertInfoBox } from '../CertificationExpertList/styles';
// import { CoachFilter } from './CoachFilter';
import {
  // CoachSelectorWrap,
  ExpertCard,
  ExpertCardWrap,
  ExpertDistance,
  ExpertPrice,
  ImgBox,
  LoadingWrapper
} from './styles';
// import { isUser } from '#utils/isUser';
import { AddressModal, Modal } from '#molecules';
import { Link } from 'react-router-dom';
import { axios } from '#api/axios';
import { useMutation } from '@tanstack/react-query';
interface GetCoachListParams {
  pageParam: number;
  lat?: number;
  lng?: number;
  searchData: string;
}

interface GridSelectorProps {
  searchData: string;
  tab?: string;
  loc: string;
}

const COACH_LIST_SCROLL_TOP_KEY = 'coach-list-scroll-top';

export const CoachGridSelector = ({ tab, searchData }: GridSelectorProps) => {
  const { userInfo } = useUserInfo();
  const coachCondition = useRecoilValue($coachConditionState);
  const selectedCoachTag = useRecoilValue($selectedCoachTagState);
  // const [currentLocation, setCurrentLocation] = useState<GeolocationPosition>();
  const { currentLocation } = sessionStorageManager();
  const { setScrollRestoration } = useScrollRestoration(
    COACH_LIST_SCROLL_TOP_KEY
  );

  const DEFAULT_VALUE = {
    page: 0,
    offset: 0,
    total: 0,
    data: []
  };
  const navigate = useNavigate();
  const getCoachList = useCallback(
    async ({
      pageParam,
      searchData
    }: GetCoachListParams): Promise<IResponse<CoachAccount>> => {
      const locations = currentLocation
        ? currentLocation?.split(',')
        : [37.5408416053893, 127.07104643853];
      try {
        const page = pageParam && pageParam - 1;
        const PARAMS = {
          page: page,
          offset: PER_PAGE_NUMBER,
          tag:
            selectedCoachTag.id === 'all' ? undefined : selectedCoachTag.name,
          search: searchData,
          grade: coachCondition.grade ? 1 : 0,
          reviewCount: coachCondition.reviewCount ? 1 : 0,
          distance: coachCondition.distance ? 1 : 0,
          minPrice: coachCondition.minPrice,
          maxPrice: coachCondition.maxPrice,
          onePoint: tab === '전체' ? 0 : 1,
          lat: Number(locations[0]),
          lng: Number(locations[1])
        } satisfies IGetAllCoachAccountsProps;

        let res;
        if (userInfo) {
          res = await getAllCoachAccounts(PARAMS);
        } else {
          res = await getAllCoachAccountsPublic(PARAMS);
        }

        if (res) {
          return res as IResponse<CoachAccount>;
        } else {
          return DEFAULT_VALUE as IResponse<CoachAccount>;
        }
      } catch (e) {
        return DEFAULT_VALUE as IResponse<CoachAccount>;
      }
    },
    [currentLocation, selectedCoachTag.name, coachCondition, tab]
  );

  const {
    data: coachesList,
    isLoading,
    fetchNextPage,
    hasNextPage
  } = useInfiniteScrollQuery<CoachAccount, ICoachConditionState>({
    perPageNum: PER_PAGE_NUMBER,
    filters: { tag: selectedCoachTag.name, filter: coachCondition }, // 필터링 경우 추가
    queryKeyParam: [
      'coachList',
      searchData,
      currentLocation ? currentLocation : ''
    ],
    queryKeyBase: 'getCoach',
    queryFn: ({ pageParam = 1 }) => {
      return getCoachList({ pageParam, searchData });
    }
  });

  const { setTarget } = useIntersectionObserver({
    hasNextPage,
    fetchNextPage
  });

  const viewList = coachesList?.filter(coach => {
    if (tab === '원포인트 레슨') {
      return coach.isOnePoint === true;
    }
    return true; // tab이 'onePoint'가 아니면 모든 coach를 반환
  });

  const viewListDupChecked = viewList?.filter(
    (value, index, self) => index === self.findIndex(t => t.id === value.id)
  );

  const [clickModal, setClickModal] = useState(false);

  const [location, setLocation] = useState('');
  const [addressModalOpen, setAddressModalOpen] = useState(false);

  const [content, setContent] = useState('');
  const [phone, setPhone] = useState('');

  // const res = async () => await axios.post('https://yshg3sogkh55ohtpwnaoxzpcgm0mnzlz.lambda-url.ap-northeast-2.on.aws/', )

  const { mutate } = useMutation({
    mutationFn: async () =>
      await axios.post(
        'https://yshg3sogkh55ohtpwnaoxzpcgm0mnzlz.lambda-url.ap-northeast-2.on.aws/',
        {
          region: location,
          content: content,
          phone: phone
        }
      )
  });

  return (
    <div className={`h-full pb-60 flex flex-col gap-24 items-center`}>
      {/* <CoachFilter /> */}
      {/* <ExpertFilterBox /> */}
      {isLoading ? (
        <LoadingWrapper>
          <LoadingSpinner />
        </LoadingWrapper>
      ) : (
        <ExpertCardWrap>
          {viewListDupChecked?.map(coach => (
            <ExpertCard
              onClick={() => {
                setScrollRestoration();
                navigate(`/expert/${coach.myUrl}`);
              }}
              key={coach.id}
            >
              <ImgBox>
                {coach.profile.length ? (
                  <MediaRenderer resizedLink={coach.profile[0].resizedLink} />
                ) : (
                  <AltImage className="w-full h-full" />
                )}
              </ImgBox>
              <ExpertInfoBox>
                <ExpertFieldBox
                  field={
                    coach.shortIntroduction
                      ? coach.shortIntroduction
                      : coach.tags[0]?.name + ' 전문가'
                  }
                />
                <ExpertNameBox name={coach.name} />
                <Text
                  textColor="gray"
                  textStyle="c2"
                  style={{ lineHeight: '100%', marginTop: '1.1rem' }}
                >
                  <ExpertDistance>
                    {coach.distance
                      ? (coach.distance / 1000).toFixed(1) + 'km'
                      : ''}
                  </ExpertDistance>
                  {coach.distance ? ' | ' : ''}경력{' '}
                  {getCoachCareer(coach.startAthletic)}년 | 트레이닝 성과{' '}
                  {coach.clinicalDataCount}건
                </Text>
                <ExpertTagsBox tags={coach.tags} />
                <Text
                  textColor="gray"
                  textStyle="c2"
                  style={{ lineHeight: '100%', marginTop: '1.2rem' }}
                >
                  {`${coach.priceType === 'OT' ? coach.round ?? 1 : 1}회`}{' '}
                  <ExpertPrice>
                    {(coach.priceType === 'OT'
                      ? coach.price * (coach.round ?? 1)
                      : coach.price
                    )?.toLocaleString('ko-KR')}
                    원
                  </ExpertPrice>
                </Text>
              </ExpertInfoBox>
            </ExpertCard>
          ))}
        </ExpertCardWrap>
      )}
      {!hasNextPage ? (
        <div className="flex flex-col items-center w-full gap-12 text-center text-[#8E899F] text-[12px] leading-16 mt-auto">
          도움이 더 필요하신가요?
          <div className="flex w-full gap-8">
            <Link
              to={'/talk/qna/write'}
              className="w-full rounded-8 border border-solid border-[#4D495A] px-18 py-[11.5px] h-44 text-[#C9C7D1] font-medium"
            >
              전문가에게 질문하기
            </Link>
            <button
              onClick={() => setClickModal(true)}
              className="w-full rounded-8 bg-[#7750E9] px-18 py-[11.5px] h-44 text-[#EAE8ED] font-medium"
            >
              전문가 요청하기
            </button>
          </div>
        </div>
      ) : (
        <div className="flex w-full px-20 max-w-[939px] items-center text-center fixed bottom-[80px] justify-evenly">
          <button
            type="button"
            onClick={() => setClickModal(true)}
            className="w-full h-44 px-20 py-12 rounded-16 text-[#EAE8ED] text-[14px] bg-[#7750E9] flex items-center justify-between"
          >
            찾으시는 전문가가 있으신가요?
            <NextArrow color="#EAE8ED" />
          </button>
        </div>
      )}
      <div ref={setTarget} style={{ height: '5px' }} />
      {clickModal && (
        <Modal onClose={() => setClickModal(false)}>
          <div className="w-full text-[12px] text-[#8E899F] ">
            <h1 className="text-[20px] font-semibold text-[#C9C7D1]">
              전문가 요청하기
            </h1>
            입력하신 내용을 바탕으로 딱 맞는 전문가를 찾아
            <br /> 문자로 안내드립니다.
          </div>
          <div className="flex flex-col w-full gap-20">
            <div className="flex flex-col gap-12">
              <label>지역</label>
              <div className="flex w-full justify-between items-center bg-[#2E2B36] rounded-4 px-16">
                <input
                  className="py-8 text-[14px] placeholder:text-[14px]text-[#5C576B] bg-[#2E2B36] placeholder:text-[#5C576B] w-[80%]"
                  type="text"
                  value={location}
                  onChange={e => setLocation(e.target.value)}
                  placeholder="요청 위치를 등록해 주세요"
                />
                {/* <button
                  onClick={() => setAddressModalOpen(true)}
                  className=" text-[12px] font-medium text-[#9170F2] leading-16 w-[20%]"
                >
                  지역 입력
                </button> */}
              </div>
            </div>
            <div className="flex flex-col gap-12">
              <label>내용</label>
              <input
                className="rounded-4 px-16 py-8 placeholder:text-[14px] w-full bg-[#2E2B36] placeholder:text-[#5C576B]"
                type="text"
                placeholder="해결하고 싶은 내용을 작성해 주세요"
                onChange={e => setContent(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-12">
              <label>전화번호</label>
              <input
                className="rounded-4 px-16 py-8 placeholder:text-[14px] w-full bg-[#2E2B36] placeholder:text-[#5C576B]"
                type="text"
                placeholder="원하시는 전문가 정보를 문자로 알려드려요"
                onChange={e => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="flex w-full gap-12 mt-40">
            <button
              type="button"
              onClick={() => setClickModal(false)}
              className="w-full bg-[#24222A] px-40 py-12 text-center rounded-8"
            >
              취소
            </button>
            <button
              type="button"
              className="w-full bg-[#7750E9] px-40 py-12 text-center rounded-8"
              onClick={() => {
                mutate();
                setClickModal(false);
              }}
            >
              요청하기
            </button>
          </div>
        </Modal>
      )}
      {addressModalOpen && (
        <AddressModal
          onComplete={value => {
            setLocation(value.address);
            setAddressModalOpen(false);
          }}
          onCancel={() => {
            setAddressModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

const MediaRenderer = ({ resizedLink }: { resizedLink: string }) => {
  const isVideo = resizedLink.includes('mp4');

  return (
    <>
      {isVideo ? (
        <video src={resizedLink + '#t=0.01'} />
      ) : (
        <img src={resizedLink} />
      )}
    </>
  );
};

export type HEALTH_INFO_TYPE = '운동 경력' | '병력' | '수술 이력 / 통증' | '';

export interface HealthInfoType {
  type: HEALTH_INFO_TYPE;
  modalTitle: string;
  optionsTitle: string;
  selectTitle?: string;
}

export const HEALTH_INFO_HEIGHT: {
  label: string;
  value: 'height' | 'weight';
  measurement: string;
}[] = [
  {
    label: '키',
    value: 'height',
    measurement: 'cm'
  },
  {
    label: '몸무게',
    value: 'weight',
    measurement: 'kg'
  }
];

export const HEALTH_INFO_LIST: HealthInfoType[] = [
  {
    type: '운동 경력',
    modalTitle: '운동 경력 추가',
    optionsTitle: '종목',
    selectTitle: '경력'
  },
  {
    type: '병력',
    modalTitle: '병력 추가',
    optionsTitle: '병력 내용'
  },
  {
    type: '수술 이력 / 통증',
    modalTitle: '수술 부위 / 통증 추가',
    optionsTitle: '부위',
    selectTitle: '통증의 정도'
  }
];

export const HEALTH_INFO_SELECT_LIST: Record<
  string,
  Array<{ label: string; value: number }>
> = {
  painParts: [
    {
      label: '0 - 현재 불편함 없음',
      value: 0
    },
    {
      label: '1 - 가벼운 불편감 존재',
      value: 1
    },
    {
      label: '2 - 약간의 불편함 존재',
      value: 2
    },
    {
      label: '3 - 눈에띄는 불편함 존재',
      value: 3
    },
    {
      label: '4 - 지속적인 불편함 존재',
      value: 4
    },
    {
      label: '5 - 극심한 불편함 존재',
      value: 5
    }
  ],
  exerciseExperienceParts: [
    {
      label: '1년',
      value: 1
    },
    {
      label: '2년',
      value: 2
    },
    {
      label: '3년',
      value: 3
    },
    {
      label: '4년',
      value: 4
    },
    {
      label: '5년 이상',
      value: 5
    }
  ]
};

import styled, { keyframes } from 'styled-components';

interface BarChartProps {
  data: number[]; // 데이터 배열
  tab: string; //현재 선택된 탭
}

export const BarChart = ({ data, tab }: BarChartProps) => {
  const startWeight = data[0];
  const endWeight = data[data.length - 1];
  const maxBarHeight = 140; // 막대의 최대 높이를 140px로 설정

  // 시작 무게와 끝 무게 중 큰 값을 기준으로 비율을 계산
  const maxWeight = Math.max(startWeight, endWeight);
  const startWeightHeight = (startWeight / maxWeight) * maxBarHeight;
  const endWeightHeight = (endWeight / maxWeight) * maxBarHeight;
  return (
    <BarChartContainer>
      <BarContainer>
        <BarValue color="#8e899f">{startWeight}</BarValue>
        <WeightBar
          height={startWeightHeight}
          backgroundColor="#575166"
        ></WeightBar>
        <BarLabel color="#8e899f">시작 {tab}</BarLabel>
      </BarContainer>
      <BarContainer>
        <BarValue color="#4caf50">{endWeight}</BarValue>
        <WeightBar
          height={endWeightHeight}
          backgroundColor="#4caf50"
        ></WeightBar>
        <BarLabel color="#eae8ed">종료 {tab}</BarLabel>
      </BarContainer>
    </BarChartContainer>
  );
};

const BarChartContainer = styled.div`
  padding: 0 2rem;
  text-align: center;
  width: 100%;
  height: 20rem;
  display: flex;
  gap: 4rem;
  justify-content: center;
`;

const BarContainer = styled.div`
  display: flex;
  width: 8rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 0.6rem;
`;

const fillAnimation = (height: number) => keyframes`
  from {
    height: 0;
  }
  to {
    height: ${height / 10}rem;
  }
`;

const WeightBar = styled.div<{ height: number; backgroundColor: string }>`
  background-color: ${props => props.backgroundColor};
  border-radius: 0.8rem;
  width: 6.4rem;
  height: ${props => props.height / 10}rem;
  animation: ${props => fillAnimation(props.height)} 1s ease-out forwards;
`;

const BarValue = styled.div<{ color: string }>`
  font-size: 1.4rem;
  color: ${props => props.color};
`;

const BarLabel = styled.div<{ color: string }>`
  font-size: 1.3rem;
  color: ${props => props.color};
`;

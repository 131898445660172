import { axios } from '#api/axios';
import { useQuery } from '@tanstack/react-query';
import ReviewCard from 'src/refactor_components/portfolio/card-reivew';

type ReviewProps = {
  address: string;
  averageRate: string;
  coachMyUrl: string;
  desc: string;
  hasWriteColumnPermission: boolean;
  id: string;
  profileImage: string;
  reviewImages: string[];
  userNickname: string;
  trainerNickname: string;
  userGender: string;
  userAge: string;
  lectureTitle: string;
  rounds: number;
};

const Reviews = () => {
  const { data } = useQuery({
    queryKey: ['get-clinical-data-consumer-portfolio-reviews'],
    queryFn: async () =>
      await axios.get<ReviewProps[]>(`rates/consumer/portfolio/reviews`),
    select: data => data.data
  });

  return (
    <>
      {data &&
        data.map(item => (
          <ReviewCard
            key={item.id}
            trainerName={item.trainerNickname}
            address={item.address}
            trainerUrl={item.coachMyUrl}
            profileImage={item.profileImage}
            hasPermission={item.hasWriteColumnPermission}
          >
            <ReviewCard.info
              lectureTitle={item.lectureTitle}
              rounds={item.rounds}
              age={item.userAge}
              userName={item.userNickname}
              sex={item.userGender}
              rate={item.averageRate}
            >
              <ReviewCard.image
                content={item.desc}
                reviewImage={item.reviewImages}
              />
            </ReviewCard.info>
          </ReviewCard>
        ))}
    </>
  );
};

export default Reviews;

import { Text } from '#atoms';
// import MiniChart from 'src/components/pages/DashboardChart/MiniChart';
import { ChartTab, StatusChangeLabel, Tab, TextBox } from './styles';
import { useMemo, useState } from 'react';
// import ChartIcon from '#assets/svg/Chart.svg';
// import barChart from '#assets/svg/bar_Chart.svg';
import { HighlightCard } from 'src/components/templates/HighlightCardTemplate';
import { Calculator } from './calculator';
import { BarChart } from 'src/components/pages/DashboardChart/BarChart';
export const HighlightChart = ({
  data
}: {
  data: {
    weight: number[];
    bodyFat: number[];
    muscle: number[];
    sex: string;
  };
}) => {
  const [selectedTab, setSelectedTab] = useState('체중');
  // const [selectedChart, setSelectedChart] = useState(false); // true 일경우 그래프/ false 바 차트
  const [changeState, setChangeState] = useState(
    data.weight[0] - data.weight[data.weight.length - 1]
  );
  const [chartData, setChartData] = useState(data.weight);
  const cal = Calculator;

  const [before, setBefore] = useState({
    label: cal.calculateBMI({
      gender: data.sex,
      bmi: data.weight[0]
    })?.label,
    color: cal.calculateBMI({
      gender: data.sex,
      bmi: data.weight[0]
    })?.color
  });
  const [after, setAfter] = useState({
    label: cal.calculateBMI({
      gender: data.sex,
      bmi: data.weight[data.weight.length - 1]
    })?.label,
    color: cal.calculateBMI({
      gender: data.sex,
      bmi: data.weight[data.weight.length - 1]
    })?.color
  });

  const chartTabList = useMemo(() => {
    const list = [];

    if (data.weight?.length) {
      list.push('체중');
    }
    if (data.bodyFat?.length) {
      list.push('체지방률');
    }
    if (data.muscle?.length) {
      list.push('골격근량');
    }
    return list;
  }, [data]);

  const dataHandler = (tab: string) => {
    if (tab === '체중') {
      setBefore({
        label: cal.calculateBMI({
          gender: data.sex,
          bmi: data.weight[0]
        })?.label,
        color: cal.calculateBMI({
          gender: data.sex,
          bmi: data.weight[0]
        })?.color
      });
      setAfter({
        label: cal.calculateBMI({
          gender: data.sex,
          bmi: data.weight[data.weight.length - 1]
        })?.label,
        color: cal.calculateBMI({
          gender: data.sex,
          bmi: data.weight[data.weight.length - 1]
        })?.color
      });
      setChangeState(data.weight[0] - data.weight[data.weight.length - 1]);
      setChartData(data.weight);
    } else if (tab === '체지방률') {
      setBefore({
        label: cal.calculateBodyFat({
          gender: data.sex,
          bodyFat: data.bodyFat[0]
        })?.label,
        color: cal.calculateBodyFat({
          gender: data.sex,
          bodyFat: data.bodyFat[0]
        })?.color
      });
      setAfter({
        label: cal.calculateBodyFat({
          gender: data.sex,
          bodyFat: data.bodyFat[data.bodyFat.length - 1]
        })?.label,
        color: cal.calculateBodyFat({
          gender: data.sex,
          bodyFat: data.bodyFat[data.bodyFat.length - 1]
        })?.color
      });
      setChangeState(data.bodyFat[0] - data.bodyFat[data.bodyFat.length - 1]);
      setChartData(data.bodyFat);
    } else if (tab === '골격근량') {
      setBefore({
        label: cal.calculateBodyFat({
          gender: data.sex,
          bodyFat: data.muscle[0]
        })?.label,
        color: cal.calculateBodyFat({
          gender: data.sex,
          bodyFat: data.muscle[0]
        })?.color
      });
      setAfter({
        label: cal.calculateBodyFat({
          gender: data.sex,
          bodyFat: data.muscle[data.muscle.length - 1]
        })?.label,
        color: cal.calculateBodyFat({
          gender: data.sex,
          bodyFat: data.muscle[data.muscle.length - 1]
        })?.color
      });
      setChangeState(data.muscle[0] - data.muscle[data.muscle.length - 1]);
      setChartData(data.muscle);
    }
  };
  return data.weight?.length && data.bodyFat?.length && data.muscle?.length ? (
    <HighlightCard>
      <TextBox>
        <Text
          style={{
            color: 'var(--color-gray-text2)',
            fontSize: '1.4rem',
            lineHeight: '2rem'
          }}
        >
          데이터 변화
        </Text>
        <Text
          textStyle="bb"
          style={{
            fontSize: '2rem',
            color: '#EAE8ED',
            lineHeight: '2.6rem',
            textAlign: 'center',
            fontWeight: 700
          }}
        >
          {selectedTab}이
          <br />
          {Math.abs(changeState)} {changeState < 0 ? '증가' : '감소'}했어요
        </Text>
        <StatusChangeLabel>
          <Text>
            <span
              style={{
                color: before.color
              }}
            >
              {before.label}
            </span>
            에서{' '}
            <span
              style={{
                color: after.color
              }}
            >
              {after.label}
            </span>
            으로 변화했어요!
          </Text>
        </StatusChangeLabel>
      </TextBox>
      {/* {selectedChart ? (
        <MiniChart
          data={chartData}
          xLabels={dummyData.xLabels}
          circleColor={dummyData.circleColor}
          // legend={dummyData.legend}
          // hasYLables={dummyData.hasYLables}
        /> */}
      <BarChart data={chartData} tab={selectedTab} />
      <ChartTab>
        {chartTabList.map(tab => (
          <Tab
            isSelected={tab === selectedTab}
            onClick={() => {
              dataHandler(tab);
              setSelectedTab(tab);
            }}
          >
            {tab}
          </Tab>
        ))}
        {/* <IconBox onClick={() => setSelectedChart(!selectedChart)}>
          <img src={!selectedChart ? ChartIcon : barChart} width={30} />
        </IconBox> */}
      </ChartTab>
    </HighlightCard>
  ) : null;
};

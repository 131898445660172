import { PATH } from '#const/path';
import {
  CoachPage,
  // CommunityPage,
  FinalRatingPage,
  FinalRatingSuccessPage,
  KakaoLoginSuccessPage,
  KakaoLogoutSuccessPage,
  LectureChartPage,
  MainIntroductionPage
} from '#pages';
import { useUserInfo } from '#providers/UserInfo';
import MetaHelmet from '#utils/MetaHelmet';
import { makeFinalDestination } from '#utils/makeFinalDestination';
import { NativeInterceptor } from '#utils/native-interceptor';
import sessionStorageManager from '#utils/sessionStorageManager';
import TokenController from '#utils/tokenController';
import { useWNInterface } from '#utils/wni';
import {
  ApplicationView,
  BillingView,
  ChatView,
  CoachView,
  DashboardView,
  KakaoLoginErrorView,
  MyPageView,
  ProfileView,
  SignView,
  WelcomeView
} from '#views';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams
} from 'react-router-dom';
import styled from 'styled-components';
import GlobalStyle from './GlobalStyles';
import { EduPage } from './components/pages/edu/page';
import { ChartView } from './components/views/ChartView';
import { LicenseView } from './components/views/LicenseView';
import { TeamView } from './components/views/TeamView';
import NotFoundPage from './pages/NotFound';
import ReviewPage from './components/pages/Review';
import MainPage from './r_page/main';
import Navigation from './r_page/FooterNavigation';
import TrainerList from './r_page/TrainerList';
import { CommunityView } from './components/views/CommunityView-refactor';
import Portfolio from './r_page/portfolio';
// import MyPage from './r_page/my-page';

interface RouteWithConditionProps {
  isView: boolean;
}

const TopWrapper = styled.div`
  margin: 0 auto;
  max-width: var(--max-width);
  width: 100%;
  height: 100%;
  position: relative;
`;

const RouteWithCondition = ({ isView }: RouteWithConditionProps) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { setAlarmTalkRedirectPath } = sessionStorageManager();

  useEffect(() => {
    if (isView) return;
    TokenController.clear();
  }, []);

  if (!isView) {
    const coachName = searchParams.get('coach-name');
    const redirectPath = coachName
      ? `${PATH.SIGN_ALARM}?coach-name=${coachName}`
      : PATH.SIGN;

    if (coachName) {
      setAlarmTalkRedirectPath(
        makeFinalDestination(coachName, searchParams, location.pathname)
      );
    }

    return (
      <Navigate
        replace={true}
        to={redirectPath}
        state={{ blockedPagePath: location.pathname + location.search }}
      />
    );
  }

  return <Outlet />;
};

const App = () => {
  const { isLogIn } = useUserInfo();
  const nativeInterceptor = new NativeInterceptor();
  const wni = useWNInterface();

  useEffect(() => {
    nativeInterceptor.initialize();
    initWNInterface();
    if (process.env.REACT_APP_NODE_ENV !== 'production') return;
    const tagManagerArgs = {
      gtmId: `${process.env.REACT_APP_GTM_ID}`
    };

    ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);
    TagManager.initialize(tagManagerArgs);
  }, []);

  function initWNInterface() {
    wni.execute('wnWebContentLoaded', {});

    return new Promise(resolve => {
      wni.onReady(() => {
        wni.onAppear(() => {
          onSetBadgeNumber();
        });
        resolve(wni);
      });
    });
  }

  function onSetBadgeNumber(number = 0) {
    wni.execute('wnPushBadgeNumber', {
      number
    });
  }

  let vh = 0;

  useEffect(() => {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  const NavigationOutlet = () => {
    return (
      <Navigation>
        <Outlet />
      </Navigation>
    );
  };

  return (
    <>
      <div className="h-full">
        <GlobalStyle />
        <TopWrapper>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Navigate to={'/main'} />} />
              <Route path="/trainer" element={<Navigate to={'/'} />} />
              <Route path="/sign/*" element={<SignView />} />
              <Route path="/loginSuccess" element={<KakaoLoginSuccessPage />} />
              <Route
                path="/newSocialAccount"
                element={<KakaoLoginSuccessPage />}
              />
              <Route element={<NavigationOutlet />}>
                {/* <Route path="/mainpage" element={<MainPage />} /> */}
                <Route path="/trainerList" element={<TrainerList />} />
                <Route path="/talk/*" element={<CommunityView />} />
                <Route path="/introduce" element={<MainIntroductionPage />} />
                <Route path="/portfolio/:id" element={<Portfolio />} />
                <Route path="/main" element={<MainPage />} />
                <Route path="/home" element={<Navigate to="/" />} />
                <Route element={<RouteWithCondition isView={isLogIn} />}>
                  <Route path="/chart" element={<LectureChartPage />} />
                  <Route path="/review-request" element={<ReviewPage />} />
                </Route>

                <Route path="/expert/*" element={<CoachView />} />
                <Route path="/team/*" element={<TeamView />} />
                <Route path="/welcome/*" element={<WelcomeView />} />
                <Route
                  path="/auth/leave"
                  element={<KakaoLogoutSuccessPage />}
                />
                <Route path="/license/*" element={<LicenseView />} />
                <Route path="/edu/:educationUrl" element={<EduPage />} />

                <Route path="/error/*" element={<KakaoLoginErrorView />} />
                <Route element={<RouteWithCondition isView={isLogIn} />}>
                  <Route path="/mypage/*" element={<MyPageView />} />

                  <Route path="/chat/*" element={<ChatView />} />
                  <Route path="/application/*" element={<ApplicationView />} />
                  <Route path="/profile/*" element={<ProfileView />} />
                  <Route path="/final-rating" element={<FinalRatingPage />} />
                  <Route
                    path="/final-rating-success"
                    element={<FinalRatingSuccessPage />}
                  />
                  <Route path="/dashboard/*" element={<DashboardView />} />
                  <Route path="/billing/*" element={<BillingView />} />
                  <Route path="/chart/*" element={<ChartView />} />
                </Route>
                {/* 매칭되지 않는 모든 경로에 대해 404 페이지 렌더링 */}
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </TopWrapper>
      </div>
    </>
  );
};

export default App;

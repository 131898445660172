import { KakaoMap } from '#atoms';
import { PATH } from '#const/path';
import { Modal } from '#molecules';
import { GymLocationPage } from '#pages';
import { IGym } from '#types/gyms';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  MoreBox,
  MoreBtn
} from 'src/components/organisims/LectureLocation/styles';
import { GymItems } from 'src/components/templates/CoachDetailContent/DetailInfo/LecturePlace/GymItems';
import { MapModal } from 'src/components/templates/CoachDetailContent/DetailInfo/LecturePlace/modal';
import EmptyContent from 'src/refactor_shared/ui/empty-content';

const TrainerLocation = ({ recommendGyms }: { recommendGyms: IGym[] }) => {
  const navigate = useNavigate();

  const [targetIndex, setTargetIndex] = useState<number>(0);

  const [detailImage, setDetailImage] = useState<string | null>(null);
  const [isMore, setIsMore] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [mapModal, setMapModal] = useState(false);
  const [mapModalGymId, setMapModalGymId] = useState<string>('');

  // const handleLocationButtonClick = (gymId: string) => {
  //   gymId && navigate(PATH.GYM_LOCATION, { state: { gymId } });
  // };
  const handleDetailClick = (image: string | null) => {
    setDetailImage(image);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
    setDetailImage(null);
  };

  return (
    <div className="flex flex-col gap-20">
      <p className="text-[18px] font-bold">수업 장소</p>
      {recommendGyms?.length ? (
        <div className="flex flex-col justify-start gap-8">
          <div className="flex justify-start items-center gap-8">
            {recommendGyms.map((gym, index) => (
              <div key={`${gym.address}-${index}`}>
                <input
                  type="radio"
                  className="peer hidden"
                  id={`gym-${index}`}
                  name="test"
                  value={index}
                  onChange={() => setTargetIndex(index)}
                  checked={index === targetIndex}
                />
                <label
                  htmlFor={`gym-${index}`}
                  className="rounded-22 py-6 px-16 border border-solid peer-checked:border-[#9170F2] peer-checked:text-[#9170F2] border-color-[#5C576B] text-[#5C576B] w-fit"
                >
                  장소 {index + 1}
                </label>
              </div>
            ))}
          </div>
          <div
            className="flex flex-col gap-16 mt-8"
            key={recommendGyms[targetIndex].id}
          >
            <div className="flex flex-col gap-20">
              <div
                className="rounded-8 overflow-hidden w-full h-120"
                onClick={() => {
                  if (recommendGyms[targetIndex].id) {
                    setMapModalGymId(recommendGyms[targetIndex].id);
                    setMapModal(true);
                  }
                }}
              >
                {!mapModal && (
                  <KakaoMap
                    latitude={Number(recommendGyms[targetIndex]?.lat)}
                    longitude={Number(recommendGyms[targetIndex]?.long)}
                    onMarkerClick={() => {
                      if (recommendGyms[targetIndex].id) {
                        setMapModalGymId(recommendGyms[targetIndex].id);
                        setMapModal(true);
                      }
                    }}
                  />
                )}
              </div>
              <div>
                <p style={{ fontSize: '16px' }}>
                  {recommendGyms[targetIndex]?.name}
                </p>
                <p style={{ fontSize: '14px' }}>
                  {recommendGyms[targetIndex]?.address}
                </p>
              </div>
              <MoreBox isMore={isMore}>
                {recommendGyms.length && recommendGyms[0].images.length > 0 && (
                  <div className="flex flex-col gap-10 py-5">
                    <p>장소 사진</p>
                    <div className="flex gap-8 w-full overflow-x-auto">
                      {recommendGyms[0].images.map(
                        (img: {
                          resizedLink: string;
                          uploadedLink: string;
                          id: string;
                        }) => (
                          <div key={`${img.resizedLink}-${img.id}`}>
                            <img
                              src={img.resizedLink}
                              className="w-[9.25rem] h-[9.25rem] object-cover rounded-[0.5rem]"
                              onClick={() =>
                                handleDetailClick(img.uploadedLink)
                              }
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
                <div className="flex flex-col gap-10 py-5">
                  <p>편의 시설</p>
                  <GymItems data={recommendGyms[0]} />
                </div>
                <div className="flex flex-col gap-10 py-5">
                  <p>전화번호</p>
                  <p style={{ whiteSpace: 'pre-wrap' }}>
                    {recommendGyms[targetIndex]?.phone}
                  </p>
                </div>
                <div className="flex flex-col gap-10 py-5">
                  <p>공간 소개</p>
                  <p style={{ whiteSpace: 'pre-wrap' }}>
                    {recommendGyms[targetIndex]?.introduction}
                  </p>
                </div>
              </MoreBox>
              <MoreBtn isMore={isMore} onClick={() => setIsMore(!isMore)}>
                {isMore ? '접기' : '더보기'}
              </MoreBtn>
            </div>
          </div>
        </div>
      ) : (
        <EmptyContent>등록된 장소가 없습니다</EmptyContent>
      )}
      {modalOpen && detailImage && (
        <Modal onClose={handleModalClose} transparent>
          <img className="modal-img" src={detailImage} />
        </Modal>
      )}
      {mapModal && (
        <MapModal onClose={() => setMapModal(false)}>
          <GymLocationPage setMapModal={setMapModal} gymId={mapModalGymId} />
        </MapModal>
      )}
    </div>
  );
};

export default TrainerLocation;

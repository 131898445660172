import { useNavigate, useParams } from 'react-router';

import * as S from './style';
import useBranch from 'src/refactor_shared/hooks/useBranch';
import Results from './list/results';
import Reviews from './list/reviews';

const Portfolio = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const { Trunk, Branch } = useBranch(id ? id : 'results');

  return (
    <div className="px-20 pb-80">
      <S.TabList>
        {SCHEDULE_REGISTRATION_TAB_LIST.map(item => (
          <S.TabItem
            key={item.route}
            isActive={item.route === id}
            onClick={() => {
              navigate(item.pathname);
            }}
          >
            {item.label}
          </S.TabItem>
        ))}
      </S.TabList>

      <Trunk>
        <Branch branchName="results" children={<Results />} />
        <Branch branchName="real-reviews" children={<Reviews />} />
      </Trunk>
    </div>
  );
};

export default Portfolio;

export const SCHEDULE_REGISTRATION_TAB_LIST = [
  {
    label: '포트폴리오',
    pathname: '/portfolio/results',
    route: 'results'
  },
  {
    label: '리얼 리뷰',
    pathname: '/portfolio/real-reviews',
    route: 'real-reviews'
  }
];

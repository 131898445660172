import { ReactNode, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import FibudCertificate from '#assets/svg/fibud-certificate.svg';
import { useChat } from '#providers/Chat';
import { PATH } from '#const/path';

const PortfolioCard = ({
  id,
  children,
  trainerName,
  trainerUrl,
  trainerProfileImage,
  address
}: {
  id: string;
  children: ReactNode;
  trainerName: string;
  trainerUrl: string;
  trainerProfileImage: string;
  address: string;
}) => {
  const [target, setTarget] = useState([true, false]);
  const childrens = document.querySelectorAll('.snap');

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0.5) {
        setTarget([false, true]);
      } else {
        setTarget([true, false]);
      }
    });
  });

  childrens.forEach(child => {
    observer.observe(child);
  });

  return (
    <div className="py-28 flex flex-col gap-20">
      <div className="flex flex-col justify-center items-center p-20 gap-20 rounded-16 bg-[#24222A]">
        <div className="flex items-center gap-20 pb-20 border-b-1 border-solid border-[#2E2B36] w-full">
          <Link to={`/expert/${trainerUrl}`}>
            <img
              src={trainerProfileImage}
              className="w-48 h-48 rounded-full bg-slate-500 object-cover"
            />
          </Link>
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4 text-[#EAE8ED]">
              <Link to={`/expert/${trainerUrl}`}>{trainerName} 전문가</Link>
              <img src={FibudCertificate} className="w-16 h-16" />
            </div>
            <p className="text-[#8E899F] text-[14px]">{address}</p>
          </div>
        </div>
        <Link
          to={`/expert/${trainerUrl}/portfolio/detail/${id}`}
          className="flex gap-20 overflow-x-scroll w-full h-full justify-start items-start"
        >
          {children}
        </Link>
        <div className="flex items-center justify-center gap-4">
          {target.map((_, index) => (
            <button
              key={index}
              className={`w-4 h-4 rounded-full bg-[#8E899F] ${
                target[index] ? '!w-16 !bg-[#C9C7D1]' : ''
              }`}
              onClick={() => {
                setTarget(prev =>
                  prev.map((_, i) => (i === index ? true : false))
                );
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const PortfolioInformation = ({
  portfolioTitle,
  rounds,
  body,
  children
}: {
  portfolioTitle: string;
  rounds: number;
  body: string;
  children: ReactNode;
}) => {
  return (
    <div className="w-full flex flex-col gap-20 flex-shrink-0 snap h-full justify-start">
      <div className="flex justify-between items-center">
        <p>{portfolioTitle}</p>
        <p className="text-[#8E899F] text-[14px]">{rounds}회</p>
      </div>
      {children}
      <p className="text-[#EAE8ED] break-words w-full whitespace-pre-line line-clamp-6">
        {body}
      </p>
    </div>
  );
};

const PortfolioInfoImage = ({
  images,
  hasMore
}: {
  images: { image: string; label: string }[];
  hasMore: number;
}) => {
  const hasMoreAfter = hasMore
    ? `after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-[#1f1e257e] after:content-[attr(after-dynamic-value)]`
    : '';

  return images?.length ? (
    <div className="w-full h-184 rounded-8 overflow-hidden flex items-center">
      {images[0]?.image && (
        <div
          className={`w-1/2 h-full relative before:z-1 ${
            images[0].label === '후'
              ? 'before:content-["후"]'
              : 'before:content-["전"]'
          } before:text-[#9170F2] before:absolute before:top-0 before:left-0 before:bg-[#1F1E25] before:my-auto before:py-4 before:rounded-br-[8px] before:w-25 before:h-25 before:text-center before:text-[10px]`}
        >
          <img
            src={images[0]?.image}
            className="object-cover rounded-tl-9 h-full w-full"
          />
        </div>
      )}
      <div className="flex flex-col h-full w-1/2">
        {images[2]?.image && (
          <div
            className={`flex-1 relative min-h-[50%] w-full before:z-1 ${
              images[2].label === '후'
                ? 'before:content-["후"]'
                : 'before:content-["전"]'
            } before:text-[#8E899F] before:absolute before:top-0 before:left-0 before:bg-[#1F1E25] before:my-auto before:py-4 before:rounded-br-[8px] before:w-25 before:h-25 before:text-center before:text-[10px]`}
          >
            <img src={images[2].image} className="object-cover h-full w-full" />
          </div>
        )}
        {images[1]?.image && (
          <div
            className={`${hasMoreAfter} flex-1 min-h-[50%] w-full relative before:z-1 after:flex after:items-center after:justify-center ${
              images[1].label === '후'
                ? 'before:content-["후"]'
                : 'before:content-["전"]'
            } before:text-[#9170F2] before:absolute before:top-0 before:left-0 before:bg-[#1F1E25] before:my-auto before:py-4 before:rounded-br-[8px] before:w-25 before:h-25 before:text-center before:text-[10px]`}
            after-dynamic-value={`+${hasMore}`}
          >
            <img src={images[1].image} className="object-cover h-full w-full" />
          </div>
        )}
      </div>
    </div>
  ) : null;
};

const PortfolioContact = ({
  id,
  trainerName,
  hasOt,
  trainerUrl
}: {
  id: string;
  trainerName: string;
  trainerUrl: string;
  hasOt: boolean;
}) => {
  const navigate = useNavigate();
  const { openChat } = useChat();

  const handleChatButtonClick = () => {
    const onSuccess = () => {
      navigate(PATH.CHAT_ROOM);
    };
    const onFail = () => {
      // setShowLoginModal(true);
      navigate(PATH.SIGN, {
        state: { blockedPagePath: location.pathname + location.search }
      });
    };
    openChat(id, onSuccess, onFail);
  };

  return (
    <div className="w-full flex flex-col items-center justify-center flex-shrink-0 snap my-auto">
      <p className="text-[#EAE8ED] text-[20px] font-bold">
        {trainerName} 전문가와
      </p>
      <p className="text-[#EAE8ED] text-[20px] font-bold">
        함께 운동하고 싶다면?
      </p>
      <p className="text-[#5C576B] text-[14px] py-12">
        {PARSE_HAS_OT[String(hasOt)].label}
      </p>
      {PARSE_HAS_OT[String(hasOt)].to === '/expert' ? (
        <Link
          to={`${PARSE_HAS_OT[String(hasOt)].to}/${trainerUrl}`}
          className="px-18 mt-20 py-11 rounded-8 text-[#EAE8ED] bg-[#7750E9]"
        >
          {PARSE_HAS_OT[String(hasOt)].linkLabel}
        </Link>
      ) : (
        <button
          className="px-18 mt-20 py-11 rounded-8 text-[#EAE8ED] bg-[#7750E9]"
          onClick={e => {
            e.preventDefault();
            handleChatButtonClick();
          }}
        >
          {PARSE_HAS_OT[String(hasOt)].linkLabel}
        </button>
      )}
    </div>
  );
};

const PARSE_HAS_OT: Record<
  string,
  { label: string; linkLabel: string; to: string }
> = {
  true: {
    label: '체험 수업으로 부담 없이 시작해보세요',
    linkLabel: '체험 수업 신청하기',
    to: '/expert'
  },
  false: {
    label: '지금 바로 채팅 상담을 받아 보세요',
    linkLabel: '1:1 채팅 상담하기',
    to: '/chat'
  }
};

PortfolioCard.info = PortfolioInformation;
PortfolioCard.image = PortfolioInfoImage;
PortfolioCard.contact = PortfolioContact;

export default PortfolioCard;

import { useGetClinicalDataList } from '#api/clinical-data';
import { useGetCoachIdFromMyUrl } from '#api/my-url';
import { Text } from '#atoms';
import { ClinicalDataItem } from '#organisims';
import { useParams } from 'react-router';
import { Container } from './styles';
import EmptyContent from 'src/refactor_shared/ui/empty-content';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { axios } from '#api/axios';

export function CoachClinicalDataPage() {
  const { myUrl } = useParams();
  const { data: coachId } = useGetCoachIdFromMyUrl(
    decodeURIComponent(myUrl || '') ?? ''
  );

  const { data: clinicalDataList } = useQuery({
    queryKey: queryKeyFactory.GET_CLINICAL_DATA_LIST({
      coachId: coachId ? coachId : ''
    }),
    queryFn: () =>
      axios.get<{
        data: {
          id: string;
          title: string;
          age: number;
          profileImage: string;
          sex: string;
        }[];
      }>(`/clinical-data/list/${coachId}`, {
        params: { offset: 100 }
      }),
    enabled: !!coachId,
    select: data => data.data
  });

  return (
    <Container>
      {clinicalDataList?.data && clinicalDataList.data.length ? (
        clinicalDataList.data.map(value => {
          return (
            <div className="flex justify-between items-center gap-12">
              <Link
                to={`portfolio/detail/${value.id}`}
                className="flex justify-start items-center gap-18"
                key={value.id}
              >
                <img
                  src={value.profileImage}
                  className="w-90 h-90 rounded-8 bg-slate-300"
                />
                <div className="flex flex-col gap-6">
                  <p className="text-[#EAE8ED] font-600">{value.title}</p>
                  <p className="text-[#8E899F] text-[14px] flex items-center gap-2">
                    <span>{value.age}세</span>
                    <span>·</span>
                    <span>{PARSE_GENDER[value.sex]}</span>
                  </p>
                </div>
              </Link>
            </div>
          );
        })
      ) : (
        <EmptyContent>트레이닝 성과가 없습니다</EmptyContent>
      )}
    </Container>
  );
}

const PARSE_GENDER: Record<string, string> = {
  male: '남성',
  female: '여성'
};

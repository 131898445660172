import React from 'react';
import { skeletonAnimtaion } from 'src/refactor_shared/util/skeletonAnimtaion';
import styled from 'styled-components';

const RecentQnaListFallbackLoader = () => {
  return (
    <div className="flex flex-col gap-12">
      {Array.from({ length: 3 }).map(() => (
        <Skeleton />
      ))}
    </div>
  );
};

export default RecentQnaListFallbackLoader;

const Skeleton = styled.div`
  width: 100%;
  min-width: 335px;
  height: 100px;
  border-radius: 12px;
  ${skeletonAnimtaion}
`;

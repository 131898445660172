import { useFormContext } from 'react-hook-form';

type CurrentLocationType = {
  documents: {
    address: {
      region_1depth_name: string;
      region_2depth_name: string;
      region_3depth_name: string;
    };
  }[];
  coords: {
    latitude: string;
    longitude: string;
  };
};

const useCurrentAddress = ({
  registerName,
  customSetValue
}: {
  registerName?: string;
  customSetValue?: (data: CurrentLocationType) => void;
}) => {
  const { setValue } = useFormContext();

  const getAddressByGeolocation = async (position: GeolocationPosition) => {
    const { coords } = position;
    const response = await fetch(
      `https://dapi.kakao.com/v2/local/geo/coord2address.json?x=${coords.longitude}&y=${coords.latitude}`,
      {
        headers: {
          Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_REST_API_KEY}`
        }
      }
    );
    const data = await response.json();

    return customSetValue
      ? customSetValue({ ...data, coords })
      : setValue(
          registerName ? registerName : '',
          data.documents[0].address.address_name
        );
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(position =>
      getAddressByGeolocation(position)
    );
  };

  return [getCurrentLocation] as const;
};

export default useCurrentAddress;

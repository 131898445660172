import { useState } from 'react';

import CommunityTag from './Tag';
import CommunityFeeds from './feed';
import Section from 'src/r_page/main/section';
import * as S from './feed/style';
import { Flex } from 'src/components/atoms/Flex';
import RankBadgeIcon from '#assets/Icon/RankBadegeIcon';
import { getWindowHeight } from 'src/refactor_shared/lib/getWindowHeight';
import { useInfiniteQuery } from '@tanstack/react-query';
import { paramsSetter } from 'src/refactor_shared/lib/paramSetter';
import { axios } from '#api/axios';
import EmptyContent from 'src/refactor_shared/ui/empty-content';
import useObserver from 'src/refactor_shared/hooks/useObserver';
import { TrainerWhiteListData } from 'src/r_page/main/constant';
import { Link, useLocation } from 'react-router-dom';
import useWatchScroll from 'src/refactor_shared/hooks/useWatchScroll';

type ThreadType = {
  id: string;
  gyms?: { id: string; name: string }[];
  writerId: string;
  category: string;
  content: string;
  sequence: number;
  title: string;
  createdAt: string;
  image: string | null;
  answerCount: number;
  name: string;
  startAthletic: string;
};

interface Props {
  isQna: boolean;
  searchData: string;
}

const CommunityArticlePage = ({ isQna, searchData }: Props) => {
  const { search } = useLocation();
  const tag = search.split('?tag=')[1];

  const queryKey = isQna
    ? 'get-infinite-qna-community-threads'
    : 'get-infinite-information-community-threads';
  const queryUrl = isQna
    ? '/consumer/qna/questions'
    : '/consumer/exercise/questions';

  const {
    data: threadGroup,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery({
    queryKey: [queryKey, searchData, tag],
    queryFn: async ({ pageParam: sequence = 0 }) => {
      const params = paramsSetter(searchData, tag);

      const response = await axios.get<Array<ThreadType>>(
        `${queryUrl}?${params.toString()}`,
        {
          params: {
            limit: 10,
            lastId: sequence
          }
        }
      );
      return response.data;
    },
    getNextPageParam: lastPage => lastPage[lastPage.length - 1]?.sequence
  });

  const [targetRef] = useObserver(fetchNextPage, hasNextPage);

  return (
    <>
      {isQna ? (
        <Section
          sectionTitle={
            <h3>
              검증된 전문가들의 답변을
              <br /> 무료로 받아보세요
            </h3>
          }
        >
          <div className="flex flex-col">
            <div className="flex w-full gap-12 overflow-x-scroll">
              {TrainerWhiteListData.map(trainer => (
                <Link
                  to={trainer.profileLink}
                  key={trainer.id} // 각 Trainer에 고유한 ID 추가
                  className="flex items-center gap-12 py-8 min-w-[323px]"
                >
                  <img
                    src={trainer.profileImage}
                    className="min-w-[76px] h-[76px] rounded-[8px] bg-[#5C576B] object-cover"
                  />

                  <S.Container>
                    <Flex direction="column" gap={4}>
                      <div className="flex items-center gap-8 text-[#9170F2]">
                        <div className="w-fit rounded-[8px] text-[14px] text-[#C9C7D1]">
                          {trainer.name} 전문가
                        </div>
                      </div>
                      <S.QuestionTitle>"{trainer.introduce}"</S.QuestionTitle>
                      <div className="flex gap-4">
                        {trainer.tag.map((data, index) => (
                          <div
                            className="px-8 py-[2.5px] w-fit flex gap-4 rounded-[22px] bg-[#24222A] text-[12px] text-[#8E899F]"
                            key={`${data}${index}`}
                          >
                            {data}
                          </div>
                        ))}
                      </div>
                    </Flex>
                  </S.Container>
                </Link>
              ))}
            </div>
          </div>
        </Section>
      ) : null}

      <div className="flex flex-col gap-20">
        <CommunityTag
          tag={tag ? tag : 'ALL'}
          targetValue="information-community"
        />
        {threadGroup?.pages ? (
          <div className="relative flex flex-col">
            <div id="content-wrap" className={`flex flex-col py-12`}>
              {threadGroup?.pages.map((threads, index) => (
                <div key={index}>
                  {threads.map(thread => (
                    <CommunityFeeds<ThreadType>
                      key={thread.id}
                      to={
                        isQna
                          ? `/talk/qna/${thread.sequence}`
                          : `/talk/guide/${thread.sequence}`
                      }
                      thread={thread}
                    />
                  ))}
                </div>
              ))}
              <div ref={targetRef} className="bottom-0 left-0 w-full p-20" />
            </div>
          </div>
        ) : (
          <EmptyContent>
            {isQna
              ? '운동 질문을 불러오고 있습니다.'
              : '운동 가이드를 불러오고 있습니다.'}
          </EmptyContent>
        )}
      </div>
    </>
  );
};

export default CommunityArticlePage;

import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '#api/axios';

import useCurrentAddress from 'src/refactor_shared/hooks/useCurrentAddress';
import { useGetCoordinates } from '#api/location';
import { AddressModal } from '#molecules';
import { useToast } from '#providers/ToastProvider';
import { BoxIcon } from '#atoms';

import {
  BottomSheetArea,
  BottomSheetWrap
} from 'src/components/organisims/ConfirmationBottomSheet/styles';

import PlaceIcon from 'src/assets/Icon/PlaceIcon.svg';
import CurrentPlaceSvg from 'src/assets/svg/CurrentPlace.svg';

type UserOnboardingType = {
  regionSigu?: string;
  regionDong?: string;
  lat?: string;
  long?: string;
  hasUserLocationOnboad?: boolean;
};

type CurrentLocationType = {
  documents: {
    address: {
      region_1depth_name: string;
      region_2depth_name: string;
      region_3depth_name: string;
    };
  }[];
  coords: {
    latitude: string;
    longitude: string;
  };
};

const UserOnboarding = () => {
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [kakaoAddress, setKakaoAddress] = useState<string>('');
  const [userAddress, setUserAddress] = useState<UserOnboardingType>({
    regionSigu: '',
    regionDong: '',
    lat: '',
    long: '',
    hasUserLocationOnboad: false
  });

  const onSetCurrentLocation = (data: CurrentLocationType) => {
    setUserAddress({
      regionSigu: `${data.documents[0].address.region_1depth_name} ${data.documents[0].address.region_2depth_name}`,
      regionDong: data.documents[0].address.region_3depth_name,
      lat: data.coords.latitude,
      long: data.coords.longitude
    });
  };

  const [getCurrentLocation] = useCurrentAddress({
    customSetValue: onSetCurrentLocation
  });

  const { data: coordinatesData } = useGetCoordinates(kakaoAddress);

  const { mutate: onLocationOnboard } = useMutation({
    mutationFn: async (payload: UserOnboardingType) =>
      await axios.patch('accounts/consumer/location-onboard', { ...payload }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-user-location-onboard']
      });
      return showToast({
        message: '위치 등록이 완료되었습니다.'
      });
    },
    onError: () => {
      return showToast({
        message: '위치 등록에 실패했습니다.'
      });
    }
  });

  useEffect(() => {
    if (!coordinatesData) return;
    setUserAddress({
      regionSigu: `${coordinatesData.documents[0].address.region_1depth_name} ${coordinatesData.documents[0].address.region_2depth_name}`,
      regionDong: coordinatesData.documents[0].address.region_3depth_name,
      lat: coordinatesData.documents[0].x,
      long: coordinatesData.documents[0].y
    });
  }, [coordinatesData]);

  return (
    <>
      <BottomSheetWrap>
        <BottomSheetArea>
          <button
            onClick={() =>
              onLocationOnboard({
                hasUserLocationOnboad: false
              })
            }
          >
            <BoxIcon type="x-gray" onlyIcon width={16} height={16} />
          </button>
          <div className="text-[24px] font-bold flex flex-col items-start gap-2 w-full pb-40">
            <p>위치를 등록하고, 내 주변</p>
            <p>전문가에게 답변 받아 보세요.</p>
          </div>
          <div className="flex flex-col items-start gap-8 w-full">
            <p className="text-[#C9C7D1]">위치</p>
            <div className="flex items-center gap-16 py-8">
              <button
                type="button"
                onClick={() => setAddressModalOpen(true)}
                className="flex items-center gap-4 text-[#8E899F] text-[14px]"
              >
                <img src={PlaceIcon} alt="" width={16} height={16} />
                {userAddress.regionSigu
                  ? userAddress.regionSigu
                  : '주소 직접 입력'}
              </button>
              <button
                type="button"
                onClick={getCurrentLocation}
                className="flex items-center gap-4 text-[#9170F2] text-[14px]"
              >
                <img src={CurrentPlaceSvg} width={16} height={16} />
                현재 위치 불러오기
              </button>
            </div>
          </div>
          <button
            className="py-16 rounded-8 bg-[#7750E9] text-center mt-72"
            onClick={() =>
              onLocationOnboard({ ...userAddress, hasUserLocationOnboad: true })
            }
          >
            완료
          </button>
        </BottomSheetArea>
      </BottomSheetWrap>
      {addressModalOpen && (
        <AddressModal
          onCancel={() => {
            setAddressModalOpen(false);
          }}
          onComplete={value => {
            setAddressModalOpen(false);
            setKakaoAddress(value.address);
          }}
        />
      )}
    </>
  );
};

export default UserOnboarding;

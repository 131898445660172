import { Route, Routes } from 'react-router';
// import { CoachClinicalDataPage, CoachDetailPage } from '#pages';
import { CoachClinicalDataPage, CoachPage } from '#pages';
import { Container } from './styles';
import TrainerDetail from 'src/r_page/trainer-profile';
import { PortfolioDetail } from 'src/r_page/portfolio/detail';

export function CoachView() {
  return (
    <Container>
      <Routes>
        <Route index element={<CoachPage />} />
        {/* <Route path="test/:myUrl" element={<CoachDetailPage />} /> */}
        <Route path="/:myUrl" element={<TrainerDetail />} />
        <Route
          path="/:myUrl/portfolio/detail/:id"
          element={<PortfolioDetail />}
        />
        <Route
          path="/clinical-data/:myUrl"
          element={<CoachClinicalDataPage />}
        />
      </Routes>
    </Container>
  );
}

import { BoxIcon } from '#atoms';

import { Link } from 'react-router-dom';
import SearchBarCloseIcon from 'src/refactor_components/common/SearchBarCloseIcon';

const SettingsMenu = () => {
  return (
    <>
      <div className="flex items-center justify-between py-16 px-20 fixed top-0 left-0 w-full text-[20px] text-[#C9C7D1] font-semibold leading-26px bg-[#121114]">
        <>설정</>
        <Link to="../.">
          <SearchBarCloseIcon />
        </Link>
      </div>
      <div className="flex flex-col gap-24">
        <div className="flex flex-col gap-32 justify-between p-20 pt-82">
          {NAVIGATE.map(value => {
            return (
              <Link
                key={value.to}
                className="flex items-center justify-between py-8 text-[#EAE8ED]"
                to={value.to}
                target={value.target}
              >
                {value.label}
                <BoxIcon type="next-arrow" onlyIcon size={14} />
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};

const NAVIGATE = [
  { to: 'account', label: '계정 관리', target: '' },
  { to: 'alarm', label: '알림 설정', target: '' },
  {
    to: 'https://fibud.notion.site/16fdbe9b1a2b802d95e8cbef80ddf221',
    label: '공지사항',
    target: '_blank'
  },
  {
    to: 'https://slashpage.com/fibudtrainer',
    label: '피벗 가이드',
    target: '_blank'
  },
  {
    to: 'http://pf.kakao.com/_cxeCPG/chat',
    label: '카카오톡 고객센터',
    target: '_blank'
  },
  {
    to: 'https://www.instagram.com/fibud_official',
    label: '피벗 인스타그램',
    target: '_blank'
  }
];

export default SettingsMenu;

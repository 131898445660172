import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { IGetDiseaseKinds } from '#types/diseaseKinds';

export const getExerciseHistory = async () => {
  const res = await request<IGetDiseaseKinds>({
    method: 'get',
    url: '/exercise/kind'
  });

  return res.data;
};

export const useGetExerciseHistory = (healthInfoType: string) => {
  return useQuery(['exercise-kind'], getExerciseHistory, {
    enabled: healthInfoType === 'exerciseExperienceParts',
    select: data => data?.data
  });
};

import { useState } from 'react';
import { useGetCoachAverageRate } from '#api/reviews';
import { BoxIcon, Text } from '#atoms';
import { FilterTab } from '#molecules';
import { FibudContainerTemplate } from 'src/components/templates/FibudContainerTemplate';
import { CoachRate } from './CoachRate';
import { CustomerReviewItem } from './CustomerReviewItem';
import { useCoachReviews } from './hooks';
import { Container, Label, RateContainer, ReviewListContainer } from './styles';

interface Props {
  coachId: string;
}

const filters = [
  { id: 1, name: '최신 순' },
  { id: 0, name: '오래된 순' },
  { id: 2, name: '평점 높은 순' },
  { id: 3, name: '평점 낮은 순' }
];

export const CustomerReviews = ({ coachId }: Props) => {
  const [filter, setFilters] = useState<number>(1);
  const {
    coachReviews,
    isLoading,
    isSuccess: isReviewSuccess
  } = useCoachReviews({
    coachId,
    filter
  });
  const { data: averageCoachRate, isSuccess: isRateSuccess } =
    useGetCoachAverageRate(coachId);

  if (!isReviewSuccess || !isRateSuccess)
    return (
      <FibudContainerTemplate type="centered">
        {isLoading && 'Loading ...'}
      </FibudContainerTemplate>
    );

  return (
    <Container>
      {averageCoachRate.averageRate !== 0 && (
        <div className="rate">
          <CoachRate data={averageCoachRate} />
        </div>
      )}

      <div className="review">
        <RateContainer>
          <Text textStyle="t3">리뷰</Text>
          <BoxIcon type="check-point-circle" size={18} onlyIcon={true} />
          <Label className="label" data-text-type="c2">
            실제 결제 고객만 작성 가능한 믿을 수 있는 리뷰입니다.
          </Label>
        </RateContainer>
        <FilterTab data={filters} filter={filter} onFilterChange={setFilters} />
      </div>
      <ReviewListContainer>
        {coachReviews.map(review => (
          <CustomerReviewItem {...review} key={review.id} />
        ))}
      </ReviewListContainer>
    </Container>
  );
};

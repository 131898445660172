import { axios } from '#api/axios';
import { useQuery } from '@tanstack/react-query';

import PortfolioCard from 'src/refactor_components/portfolio/card-portfolio';

type ResultsProps = {
  id: string;
  coachName: string;
  address: string;
  coachUrl: string;
  lectureTitle: string;
  body: string;
  rounds: number;
  images: { image: string; label: string }[];
  hasMore: number;
  hasOt: boolean;
  trainerName: string;
  coachProfileImage: string;
  coachId: string;
};

const Results = () => {
  const { data } = useQuery({
    queryKey: ['get-clinical-data-consumer-portfolio-results'],
    queryFn: async () =>
      await axios.get<{ [key: string]: ResultsProps[] }>(
        `clinical-data/consumer/portfolio/results`
      ),
    select: data => data.data
  });

  if (!data) return <></>;

  return (
    <>
      {Object.entries(data).map(([key, value]) => {
        return (
          <div key={key}>
            {value &&
              value.map((item: ResultsProps) => {
                return (
                  <PortfolioCard
                    id={item.id}
                    trainerName={item.coachName}
                    address={item.address}
                    trainerUrl={item.coachUrl}
                    trainerProfileImage={item.coachProfileImage}
                  >
                    <PortfolioCard.info
                      portfolioTitle={item.lectureTitle}
                      body={item.body}
                      rounds={item.rounds}
                    >
                      <PortfolioCard.image
                        images={item.images}
                        hasMore={item.hasMore}
                      />
                    </PortfolioCard.info>
                    <PortfolioCard.contact
                      id={item.coachId}
                      hasOt={item.hasOt}
                      trainerName={item.trainerName}
                      trainerUrl={item.coachUrl}
                    />
                  </PortfolioCard>
                );
              })}
            <div className="w-full flex justify-center gap-10 items-center">
              <div className="w-full h-1 bg-[#2E2B36]" />
              <p className="text-[#5C576B] flex-shrink-0 text-[12px]">{key}</p>
              <div className="w-full h-1 bg-[#2E2B36]" />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Results;

import { axios } from '#api/axios';
import { useNavigate, useParams } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Dispatch, SetStateAction, SyntheticEvent } from 'react';

import { useToast } from '#providers/ToastProvider';
import SaveIcon from 'src/r_page/community/Icon/SaveIcon';

import More from '#assets/svg/dots_vertical_rounded.svg';
import TailArrow from '#assets/svg/tailed_arrow.svg';
import LikeIcon from 'src/r_page/community/CommunityDetail/QuestionComments/icon/LikeIcon';
import SearchBarCloseIcon from 'src/refactor_components/common/SearchBarCloseIcon';
import { ShareIcon } from '#assets/Icon/ShareIcon';
import {
  usePostExerciseLikesMutation,
  usePostInformationCommentMutation
} from '#api/community';
import LikedIcon from '#assets/svg/liked.svg';
import ProfileIcon from '#assets/Icon/navigation/ProfileIcon';
import { useUserInfo } from '#providers/UserInfo';
import { AxiosError } from 'axios';
import useThreadLikeMutations from 'src/r_page/Information-community/useThreadLikeMutations';
import { isUser } from '#utils/isUser';
import Bookmarked from '#assets/svg/bookmarked.svg';
import FibudCertificate from '#assets/svg/fibud-certificate.svg';
import { Link } from 'react-router-dom';
import { useShareTharedUrl } from '#utils/handleCopyClipBoard';

const InformationCommunityComments = () => {
  const { id } = useParams();

  const { data: commentCount } = useQuery({
    queryKey: ['get-information-community-thread-comment-count', id],
    queryFn: async () =>
      await axios.get(`/consumer/exercise/${id}/answers-count`),
    enabled: !!id,
    select: ({ data }) => data
  });

  return (
    <div className="flex flex-col justify-start px-20 pt-40 gap-55">
      <div className="flex gap-4">
        <h1 className="font-bold text-[18px] leading-24 text-[#EAE8ED]">
          댓글
        </h1>
        <span className="text-[16px] font-medium text-[#9170F2]">
          {commentCount ? commentCount : 0}
        </span>
      </div>
      {commentCount === 0 && (
        <span className="text-center text-[#5C576B] text-[14px] leading-20">
          아직 작성된 댓글이 없습니다
          <br /> 가장 먼저 댓글을 작성해 보세요 !
        </span>
      )}
    </div>
  );
};

const MainThreads = ({
  setIsModalOpen,
  targetId,
  userName,
  userProfile,
  content,
  createdAt,
  sequence,
  like,
  isOp,
  isLike,
  isTutor,
  myUrl
}: {
  setIsModalOpen: Dispatch<SetStateAction<string>>;
  targetId: string;
  userName: string;
  userProfile: string;
  content: string;
  createdAt: string;
  sequence: number;
  like: number;
  isOp: boolean;
  isLike: boolean;
  isTutor: boolean;
  myUrl: string;
}) => {
  const { setValue } = useFormContext();
  const { likeMutate } = useThreadLikeMutations(false);
  const queryClinet = useQueryClient();
  const { id } = useParams();

  return (
    <div className="flex flex-col gap-20 px-20 ">
      <div className="gap-17 flex flex-col text-[#C9C7D1] rounded-12 rounded-bl-none">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-8">
            <Link
              to={`/expert/${myUrl}`}
              className={`${
                myUrl ? '' : 'pointer-events-none'
              } w-32 h-32 overflow-hidden rounded-full`}
            >
              {userProfile ? (
                <img
                  src={userProfile}
                  className="w-32 h-32 overflow-hidden rounded-full"
                />
              ) : (
                <div className="w-32 h-32 overflow-hidden rounded-full">
                  <ProfileIcon />
                </div>
              )}
            </Link>
            <div className="flex flex-col gap-2">
              <p className="font-semibold text-[#EAE8ED] text-[14px] flex items-center gap-4">
                <Link
                  to={`/expert/${myUrl}`}
                  className={`${myUrl ? '' : 'pointer-events-none'}`}
                >
                  {userName}
                </Link>
                {isTutor && (
                  <img
                    src={FibudCertificate}
                    className="w-16 h-16 rounded-full"
                  />
                )}
                {/* {isOp && (
                  <span className="rounded-22 border border-solid ml-4 border-[#8E899F] my-auto text-[#8E899F] text-[10px] px-6 py-2">
                    작성자
                  </span>
                )} */}
              </p>

              <p className="text-[#5C576B] text-[10px]">
                {createdAt.split('T')[0]}
              </p>
            </div>
          </div>
          {isOp && (
            <button
              className="w-28 h-28 rounded-full py-4 px-4 bg-[#1F1E25] flex items-center justify-center"
              onClick={() => {
                setIsModalOpen('mainComment');
                setValue('content', content);
                setValue('sequence', sequence);
              }}
            >
              <img src={More} />
            </button>
          )}
        </div>
        <p className="pl-44 text-[14px] whitespace-pre-line">{content}</p>
        <div className="flex items-center justify-between pl-44">
          <div className="flex items-center gap-20 text-[14px]">
            <span className="text-[#8E899F]">더보기</span>
            <span
              className="text-[#7750E9]"
              onClick={() => {
                setValue('sequence', sequence);
                setValue('userName', userName);
              }}
            >
              답글달기
            </span>
          </div>
          <button
            type="button"
            className="flex items-center justify-end gap-4"
            onClick={() =>
              likeMutate(
                {
                  targetId: targetId,
                  kind: 'ANSWER'
                },
                {
                  onSuccess: () => {
                    queryClinet.invalidateQueries([
                      'get-information-community-thread-comment',
                      id
                    ]);
                  }
                }
              )
            }
          >
            <LikeIcon isLiked={isLike} />
            <span className="text-[12px] font-normal">{like}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const SubThreads = ({
  setIsModalOpen,
  userName,
  userProfile,
  content,
  createdAt,
  sort,
  sequence,
  isOp,
  isTutor,
  myUrl
}: {
  setIsModalOpen: Dispatch<SetStateAction<string>>;
  userName: string;
  userProfile: string;
  content: string;
  createdAt: string;
  sort: number;
  sequence: number;
  isOp: boolean;
  isTutor: boolean;
  myUrl: string;
}) => {
  const { setValue } = useFormContext();

  return (
    <div className="flex justify-end gap-10 px-20">
      <div className="gap-17 flex flex-col text-[#C9C7D1]  rounded-12 rounded-tl-none w-[calc(100%-32px)]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-8">
            <Link
              to={`/expert/${myUrl}`}
              className={`${
                myUrl ? '' : 'pointer-events-none'
              } w-32 h-32 overflow-hidden rounded-full`}
            >
              {userProfile ? (
                <img
                  src={userProfile}
                  className="w-32 h-32 overflow-hidden rounded-full"
                />
              ) : (
                <div className="w-32 h-32 overflow-hidden rounded-full">
                  <ProfileIcon />
                </div>
              )}
            </Link>
            <div className="flex flex-col gap-2">
              <p className="font-semibold text-[#EAE8ED] text-[14px] flex items-center gap-4">
                <Link
                  to={`/expert/${myUrl}`}
                  className={`${myUrl ? '' : 'pointer-events-none'}`}
                >
                  {userName}
                </Link>
                {isTutor && (
                  <img
                    src={FibudCertificate}
                    className="w-16 h-16 rounded-full"
                  />
                )}
                {/* {isOp && (
                  <span className="rounded-22 border border-solid ml-4 border-[#8E899F] my-auto text-[#8E899F] text-[10px] px-6 py-2">
                    작성자
                  </span>
                )} */}
              </p>
              <p className="text-[#5C576B] text-[10px]">
                {createdAt.split('T')[0]}
              </p>
            </div>
          </div>
          {isOp && (
            <button
              className="w-28 h-28 rounded-full py-4 px-4 bg-[#1F1E25] flex items-center justify-center"
              onClick={() => {
                setIsModalOpen('subComment');
                setValue('content', content);
                setValue('sequence', sequence);
              }}
            >
              <img src={More} />
            </button>
          )}
        </div>
        <p className="pl-44 text-[14px] whitespace-pre-line">{content}</p>
        <div className="flex items-center justify-end">
          <div className="flex items-center gap-20 text-[14px]">
            <span
              className="text-[#7750E9]"
              onClick={() => {
                setValue('sequence', sort);
                setValue('userName', userName);
              }}
            >
              답글달기
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ThreadInput = ({
  questionId,
  isLike,
  likeCount,
  receiverId,
  isBookmarked
}: {
  questionId?: string;
  isLike: boolean;
  likeCount: number;
  receiverId: string;
  isBookmarked: boolean;
}) => {
  const { getValues, setValue, register, watch } = useFormContext();
  const { userInfo } = useUserInfo();
  const userId = userInfo?.id;
  const { showToast } = useToast();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const handleInput = (event: SyntheticEvent) => {
    const textarea = event.target as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = 'auto';
      if (textarea.scrollHeight < 120) {
        textarea.style.height = textarea.scrollHeight + 'px';
      } else {
        textarea.style.height = '120px';
        textarea.style.overflow = 'auto';
      }
    }
  };

  const { mutate: postBookmarkThread } = useMutation({
    mutationFn: async (questionId?: string) =>
      await axios.post('/consumer/exercise/bookmarks', { questionId }),
    onSuccess: () => {
      queryClient.invalidateQueries(['get-information-community-thread', id]);
      showToast({
        message: '관심글로 지정하였습니다.'
      });
    },
    onError: (e: AxiosError) => {
      if (e.response?.status === 401) {
        showToast({
          type: 'fail',
          message: '로그인 후 이용하실 수 있습니다.'
        });
      } else {
        showToast({
          type: 'fail',
          message: '일시적인 에러입니다. 다시 시도해 주세요.'
        });
      }
    }
  });

  const { mutate: patchThreadComment } = useMutation({
    mutationFn: async (payload: { content: string; sequence: number }) =>
      await axios.patch(`/consumer/exercise/answers`, payload),
    onSuccess: () => {
      showToast({
        message: '댓글을 수정했습니다.'
      });
      setValue('edit', false);
      setValue('content', '');
      queryClient.invalidateQueries({
        queryKey: ['get-information-community-thread-comment', id]
      });
    },
    onError: (e: AxiosError) => {
      if (e.response?.status === 401) {
        showToast({
          type: 'fail',
          message: '로그인 후 이용하실 수 있습니다.'
        });
      } else {
        showToast({
          type: 'fail',
          message: '일시적인 에러입니다. 다시 시도해 주세요.'
        });
      }
    }
  });

  const { mutate: postExerciseLikesMutation } = usePostExerciseLikesMutation();

  // const { likeMutate } = useThreadLikeMutations(false);
  const { mutate: postInformationCommentMutation } =
    usePostInformationCommentMutation(
      questionId || '',
      getValues('sequence')
        ? {
            content: watch('content'),
            questionId: questionId || '',
            writerId: userId || '',
            originalAnswerId: getValues('sequence'),
            receiverId: receiverId,
            sequence: getValues('sequence')
          }
        : {
            content: watch('content'),
            questionId: questionId || '',
            writerId: userId || ''
          }
    );

  const navigate = useNavigate();
  const copyThreadUrl = useShareTharedUrl();

  return (
    <div className="fixed bottom-0 w-full max-w-939 bg-[#181818] border-t-1 border-solid border-[#303030] p-20 pt-10 flex flex-col items-center gap-8 ">
      {watch('userName') ? (
        <div className="text-[14px] w-full flex items-center gap-8">
          <p>
            <span className="text-[#7750E9]">{getValues('userName')}</span>{' '}
            님에게 댓글 작성 중
          </p>
          <button
            type="button"
            className="w-12 h-12"
            onClick={() => {
              setValue('sequence', undefined);
              setValue('content', '');
              setValue('userName', undefined);
            }}
          >
            <SearchBarCloseIcon />
          </button>
        </div>
      ) : null}
      {watch('edit') ? (
        <div className="text-[14px] w-full flex items-center gap-8">
          <p>댓글 수정 중</p>
          <button
            type="button"
            className="w-12 h-12"
            onClick={() => {
              setValue('sequence', undefined);
              setValue('content', '');
              setValue('edit', false);
            }}
          >
            <SearchBarCloseIcon />
          </button>
        </div>
      ) : null}
      <div className="flex items-center justify-between w-full gap-10 text-center">
        <textarea
          {...register('content', {
            onChange: handleInput
          })}
          rows={1}
          className="border border-solid border-[#5C576B] rounded-8 text-[#5C576B] py-12 px-16 bg-transparent max-h-150 w-[calc(100%-28px)]"
          placeholder="댓글을 작성해주세요"
        />
        <button
          className="w-28 h-28 rounded-full py-4 px-4 bg-[#2E2B36]"
          onClick={() => {
            getValues('edit')
              ? patchThreadComment({
                  content: getValues('content'),
                  sequence: getValues('sequence')
                })
              : postInformationCommentMutation();
          }}
        >
          <img src={TailArrow} />
        </button>
      </div>
      <div className="flex items-center justify-between w-full pt-16 text-center">
        <button
          type="button"
          className={`text-[14px] ${
            isBookmarked ? 'text-[#9170F2]' : 'text-[#8E899F]'
          } text-[#8E899F] w-full flex items-center justify-center gap-4`}
          onClick={() => {
            if (isUser()) {
              postBookmarkThread(questionId);
            } else {
              navigate('/sign');
            }
          }}
        >
          {isBookmarked ? (
            <img className="w-24 h-24" src={Bookmarked} alt="" />
          ) : (
            <SaveIcon />
          )}
          관심글
        </button>
        <button
          type="button"
          className="text-[14px] text-[#8E899F] w-full flex items-center justify-center gap-4"
          onClick={() =>
            copyThreadUrl(
              `https://fibud.kr/talk/guide/${id}`,
              '운동 가이드 URL이 복사되었습니다.'
            )
          }
        >
          <ShareIcon />
          공유하기
        </button>
        <button
          className="text-[14px] text-[#8E899F] w-full flex items-center justify-center gap-4"
          onClick={() => {
            if (isUser()) {
              postExerciseLikesMutation(
                {
                  targetId: questionId ? questionId : '',
                  kind: 'QUESTION'
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries([
                      'get-information-community-thread',
                      id
                    ]);
                  }
                }
              );
            } else {
              navigate('/sign');
            }
          }}
        >
          {isLike ? <img src={LikedIcon} /> : <LikeIcon />}
          <span
          // className={`${
          //   isLiked ? 'text-[#9170F2] font-medium' : 'text-[#8E899F]'
          // } text-[14px]`}
          >
            {likeCount}
          </span>
        </button>
      </div>
    </div>
  );
};

InformationCommunityComments.MainThreads = MainThreads;
InformationCommunityComments.SubThreads = SubThreads;
InformationCommunityComments.ThreadInput = ThreadInput;

export default InformationCommunityComments;

import { Text } from '#atoms';
import { Link } from 'react-router-dom';
import * as S from './styles';

interface ItemProps {
  data: {
    id: number;
    title: string;
    age: number;
    sex: string;
    criteria: string;
    profileImage: string;
  }[];
  to: string;
}

export const PortfolioItem = ({ data, to }: ItemProps) => {
  return (
    <S.Container style={{ gap: '1.6rem' }}>
      {data &&
        data.map((item, index) => (
          <Link to={`portfolio/${to}/${item.id}`} key={index}>
            <S.PortfolioItems key={index}>
              <S.ImageBox>
                {item.profileImage ? (
                  <img
                    src={item.profileImage}
                    className="w-full object-cover"
                  />
                ) : (
                  <div className="w-full h-120 bg-gray-300" />
                )}
              </S.ImageBox>
              {false && <IsAuthenticated />}
              {/* <p className="bg-[#1F1E25] rounded-22 text-[#9170F2] text-[10px] text-center px-7 py-[2.5px] w-fit">
                {item.criteria}
              </p> */}
              <div>
                <Text style={{ fontSize: '1.4rem' }}>{item.title}</Text>
                <Text style={{ fontSize: '1.2rem', color: '#8E899F' }}>
                  {item.age}세 · {PARSE_GENDER[item.sex]}
                </Text>
              </div>
            </S.PortfolioItems>
          </Link>
        ))}
    </S.Container>
  );
};

const IsAuthenticated = () => {
  return (
    <S.Tag>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.70534 0.214943C4.88091 0.086874 5.11909 0.0868739 5.29466 0.214943L6.18122 0.861648C6.26705 0.924258 6.37059 0.957901 6.47683 0.957698L7.5742 0.955609C7.79151 0.955196 7.98421 1.0952 8.05097 1.30201L8.38809 2.34631C8.42073 2.44741 8.48472 2.53549 8.57079 2.59777L9.4598 3.2411C9.63586 3.3685 9.70946 3.59503 9.64192 3.80158L9.30082 4.84459C9.2678 4.94556 9.2678 5.05444 9.30082 5.15541L9.64192 6.19842C9.70946 6.40497 9.63586 6.6315 9.4598 6.7589L8.57079 7.40223C8.48472 7.46451 8.42073 7.55259 8.38809 7.65369L8.05097 8.69799C7.98421 8.9048 7.79151 9.0448 7.5742 9.04439L6.47683 9.0423C6.37059 9.0421 6.26705 9.07574 6.18122 9.13835L5.29466 9.78506C5.11909 9.91313 4.88091 9.91313 4.70534 9.78506L3.81878 9.13835C3.73295 9.07574 3.62941 9.0421 3.52317 9.0423L2.4258 9.04439C2.20849 9.0448 2.01579 8.9048 1.94903 8.69799L1.61191 7.65369C1.57927 7.55259 1.51528 7.46451 1.42921 7.40223L0.540196 6.7589C0.364142 6.6315 0.290537 6.40497 0.358085 6.19842L0.699176 5.15541C0.732198 5.05444 0.732198 4.94556 0.699176 4.84459L0.358085 3.80158C0.290537 3.59503 0.364142 3.3685 0.540196 3.2411L1.42921 2.59777C1.51528 2.53549 1.57927 2.44741 1.61191 2.34631L1.94903 1.30201C2.01579 1.0952 2.20849 0.955196 2.4258 0.955609L3.52316 0.957698C3.62941 0.957901 3.73295 0.924258 3.81878 0.861648L4.70534 0.214943Z"
          fill="#FF8AC2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.90014 3.04639L7.60369 3.74994L4.25014 7.10349L2.49658 5.34994L3.20014 4.64639L4.25014 5.69639L6.90014 3.04639Z"
          fill="#1F1E25"
        />
      </svg>
      <Text style={{ fontSize: '1rem', color: '#FF8AC2' }}>운동차트 인증</Text>
    </S.Tag>
  );
};

const PARSE_GENDER: Record<string, string> = {
  male: '남성',
  female: '여성'
};

export const TRANIER_COMMUNITY_TPYE_LIST: Record<string, Array<string>> = {
  'trainer-community': ['ALL', 'COLUMN', 'KNOWHOW', 'CONCERN', 'DAILY'],
  'information-community': [
    'ALL',
    // '인기글',
    'METHOD',
    'INJURY_REHABILITATION',
    'NUTRITION_DIET',
    'LOSE_WEIGHT_BULK_UP',
    'COMPETITION_BODY_PROFILE'
  ]
};

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding-top: 50px;
`;

export const ImgBox = styled.div`
  width: 100%;
  > img {
    width: 100%;
    opacity: 0.5;
    height: 24rem;
    object-fit: cover;
  }
`;

export const PrevHeader = styled.div`
  width: 100%;
  padding: 1.6rem 2rem;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  z-index: 99;
  background-color: var(--color-background);
  > * {
    cursor: pointer;
  }
`;

export const IntroBox = styled.div`
  width: 100%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

export const ChartWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

export const GoalBox = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 0.8rem;
`;

export const GoalCard = styled.div`
  background-color: #24222a;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  color: #8e899f;
  border-radius: 0.8rem;
  > p {
    text-align: center;
    font-size: 1.6rem;
    line-height: 2.1rem;
  }
`;

export const IconTextBox = styled.div`
  display: flex;
  gap: 0.4rem;
  justify-content: center;
`;

import { useGetRecentQnaQuery } from '#api/community';
import { Link } from 'react-router-dom';

const RecentQna = () => {
  const { data: getRecentQnaData } = useGetRecentQnaQuery();

  return (
    <div className="flex flex-col gap-12">
      {getRecentQnaData?.slice(0, 3).map(item => (
        <Link
          key={item.questionSequence}
          to={`/talk/qna/${item.questionSequence}`}
          className="p-20 bg-[#24222A] flex flex-col rounded-12 min-w-335 w-full"
        >
          <p className="flex items-start line-clamp-2 leading-16">
            <span className="text-[#5C576B] text-[13px] font-semibold mr-6 pt-2">
              Q.
            </span>
            <span>{item.title}</span>
          </p>
          <p className="flex items-start line-clamp-2">
            <span className="text-[#9170F2] text-[13px] font-semibold mr-6 pt-2">
              A.
            </span>
            <div
              className="line-clamp-2"
              dangerouslySetInnerHTML={{ __html: item.answer }}
            />
          </p>
        </Link>
      ))}
    </div>
  );
};

export default RecentQna;

import { Dispatch, SetStateAction } from 'react';

interface Props {
  selectList: {
    label: string;
    value: string;
  }[];
  setSelectValue: Dispatch<SetStateAction<string>>;
  selectvalue: string;
}

const SelectTag = ({ selectList, setSelectValue, selectvalue }: Props) => {
  return (
    <div className="w-full overflow-x-auto">
      <div className="flex gap-4">
        {selectList.map(list => {
          return (
            <button
              key={list.value}
              className={`flex-shrink-0 px-12 py-8 text-[13px] border border-solid bg-[#24222A] rounded-50 ${
                selectvalue === list.value
                  ? 'text-[#7750E9] border-[#7750E9]'
                  : 'text-[#C9C7D1] border-none'
              }`}
              onClick={() => setSelectValue(list.value)}
            >
              {list.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default SelectTag;

import { axios } from '#api/axios';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { parseDateToYearMonthDay } from 'src/refactor_shared/lib/parseYear';
import recursiveParser from 'src/refactor_shared/lib/recursive-parser';
import EmptyContent from 'src/refactor_shared/ui/empty-content';

const ActivitiesQna = ({ id }: { id?: string }) => {
  const { data: qnaQuestions } = useQuery({
    queryKey: ['get-trainer-qna-questions', id],
    queryFn: async () =>
      await axios.get<
        {
          questionTitle: string;
          name: string;
          createdAt: Date;
          content: string;
          id: string;
          sequence: number;
        }[]
      >(`consumer/coach/questions/${id}`),
    select: data => data.data,
    enabled: !!id
  });

  return (
    <>
      {qnaQuestions?.length ? (
        qnaQuestions.map(value => {
          return (
            <Link
              key={value.id}
              to={`/talk/qna/${value.sequence}`}
              className="p-20 flex flex-col gap-16 border-b-1 border-solid border-[#24222A]"
            >
              <div className="flex gap-8 font-bold">
                <p className="text-[#9170F2]">Q.</p>
                <p className="text-[#FFFFFF]">{value.questionTitle}</p>
              </div>
              <div className="text-[14px] p-20 rounded-12 rounded-tl-0 bg-[#1F1E25] ml-24 flex flex-col gap-5">
                <p className="text-[#9170F2]">{value.name} 트레이너의 답변</p>
                <p className="text-[#8E899F]">
                  {parseDateToYearMonthDay(value.createdAt)}
                </p>
                <p
                  className="text-[#EAE8ED] line-clamp-3"
                  dangerouslySetInnerHTML={{
                    __html: recursiveParser(value.content, 400)
                  }}
                />
              </div>
            </Link>
          );
        })
      ) : (
        <div className="px-20 py-60">
          <EmptyContent>등록된 질문이 없습니다.</EmptyContent>
        </div>
      )}
    </>
  );
};

export default ActivitiesQna;

import styled from 'styled-components';

export const ChartBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  padding: 2.4rem;
  border-radius: 1.2rem;
  background-color: var(--color-gray-bg2);
`;

export const Container = styled.div`
  width: 100%;
  padding: 0 2rem;
`;

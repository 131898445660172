import { Link, useParams } from 'react-router-dom';
// import SaveIcon from 'src/r_page/community/Icon/SaveIcon';
// import WriteIcon from '#assets/svg/write_icon.svg';
// import { useMutation } from '@tanstack/react-query';
// import { axios } from '#api/axios';
import { useToast } from '#providers/ToastProvider';
import { ShareIcon } from '#assets/Icon/ShareIcon';
import SaveIcon from 'src/r_page/community/Icon/SaveIcon';
import QuestionIcon from '#assets/Icon/QuestionIcon';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axios } from '#api/axios';
import { useUserInfo } from '#providers/UserInfo';
import Bookmarked from '#assets/svg/bookmarked.svg';
import { usePostQnaLikesMutaiton } from '#api/community';
import { useShareTharedUrl } from '#utils/handleCopyClipBoard';

const QnaThreadNavigation = () => {
  const { id } = useParams();
  const { showToast } = useToast();

  // const { mutate: postBookmarkQuestion } = useMutation({
  //   mutationFn: async () =>
  //     await axios.post('/consumer/qna/bookmarks', { questionId: id }),
  //   onSuccess: () => {
  //     showToast({
  //       message: '관심글로 지정하였습니다.'
  //     });
  //   },
  //   onError: () => {
  //     showToast({
  //       type: 'fail',
  //       message: '일시적인 에러입니다. 다시 시도해 주세요.'
  //     });
  //   }
  // });

  return (
    <div className="flex flex-col gap-16 w-full bg-[#1F1E25] rounded-t-8 py-16 px-20 fixed bottom-0 left-1/2 -translate-x-1/2 max-w-939 border-t-1 border-solid border-[#2E2B36]">
      <p className="text-center text-[14px]">
        궁금한 게 있으신가요? 질문을 통해 고민을 해결해 보세요.
      </p>
      <div className="flex items-center justify-between gap-16">
        <Link
          to={`/talk/qna/write`}
          className="flex justify-center items-center gap-4 w-full text-center py-12 rounded-12 bg-[#7750E9] text-[#EAE8ED]"
        >
          {/* <img src={WriteIcon} className="w-16 h-14" /> */}
          전문가에게 질문하기
        </Link>
      </div>
    </div>
  );
};

export default QnaThreadNavigation;

interface Props {
  isBookmarked: boolean;
  isLike: boolean;
  questionId: string;
}

export const UserQnaThreadNavigation = ({
  isBookmarked,
  isLike,
  questionId
}: Props) => {
  const { id } = useParams();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const { mutate: postBookmarkQuestion } = useMutation({
    mutationFn: async () =>
      await axios.post('/consumer/qna/bookmarks', { questionId: questionId }),
    onSuccess: () => {
      queryClient.invalidateQueries(['get-trainer-community-save']);
      showToast({
        message: '관심글로 지정하였습니다.'
      });
      queryClient.invalidateQueries({
        queryKey: ['get-trainer-community-save']
      });
      queryClient.invalidateQueries({
        queryKey: ['get-qna-community-question', id]
      });
    },
    onError: () => {
      showToast({
        type: 'fail',
        message: '일시적인 에러입니다. 다시 시도해 주세요.'
      });
    }
  });
  const copyThreadUrl = useShareTharedUrl();

  const postQnaLikesMutation = usePostQnaLikesMutaiton();

  return (
    <div className="flex flex-col gap-16 w-full bg-[#121114] rounded-t-8 py-16 px-[39.5px] fixed bottom-0 left-1/2 -translate-x-1/2 max-w-939 border-t-1 border-solid border-[#24222A]">
      <div className="flex items-center justify-between w-full text-center">
        <button
          type="button"
          className={`text-[14px] ${
            isBookmarked ? 'text-[#9170F2]' : 'text-[#8E899F]'
          } w-full flex items-center justify-center gap-4 cursor-pointer`}
          onClick={() => postBookmarkQuestion()}
        >
          {isBookmarked ? (
            <img src={Bookmarked} alt="" className="w-24 h-24" />
          ) : (
            <SaveIcon />
          )}
          관심글
        </button>
        <button
          onClick={() =>
            copyThreadUrl(
              `https://fibud.kr/talk/qna/${id}`,
              '질문 답변 URL이 복사되었습니다.'
            )
          }
          type="button"
          className={`text-[14px] text-[#8E899F]  w-full flex items-center justify-center gap-4 cursor-pointer`}
        >
          <ShareIcon />
          공유하기
        </button>

        <button
          onClick={() =>
            postQnaLikesMutation.mutate(
              {
                kind: 'QUESTION',
                targetId: questionId || ''
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries([
                    'get-qna-community-question',
                    id
                  ]);
                }
              }
            )
          }
          className="text-[14px] text-[#8E899F] w-full flex items-center justify-center gap-4 cursor-pointer"
        >
          <QuestionIcon isLike={isLike} />
          <span className={`${isLike ? 'text-[#9170F2]' : 'text-[#8E899F]'}`}>
            궁금 했어요
          </span>
        </button>
      </div>
    </div>
  );
};

import { useState } from 'react';

import HealthInfoSelectModal from 'src/r_page/Information-community/Write/modal';
import WriteHealthInfo from 'src/r_page/Information-community/Write/components/health-info';

import { useProgress } from '#templates';
import { useApplications } from '#providers/Applications';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '#atoms';

const ApplicationHealthInfo = () => {
  const methods = useForm({
    defaultValues: {
      exerciseExperienceParts: [],
      disease: [],
      painParts: []
    }
  });

  const [additionalHealthInfoModalOpen, setAdditionalHealthInfoModalOpen] =
    useState('');

  const { next } = useProgress();
  const { updateCurrentApplications } = useApplications();

  return (
    <FormProvider {...methods}>
      <div className="p-20 pt-0 h-full flex flex-col">
        <p className="text-[18px] pb-40 mt-12">
          더 정확한 트레이닝을 위해,
          <br /> 추가 정보를 입력해주세요
        </p>
        <WriteHealthInfo
          setAdditionalHealthInfoModalOpen={setAdditionalHealthInfoModalOpen}
        />
        <div className="mt-auto">
          <Button
            onClick={() => {
              updateCurrentApplications({
                healthInfo: methods.watch()
              });
              next();
            }}
          >
            다음
          </Button>
        </div>

        {additionalHealthInfoModalOpen && (
          <HealthInfoSelectModal
            setAdditionalHealthInfoModalOpen={setAdditionalHealthInfoModalOpen}
            healthInfoType={additionalHealthInfoModalOpen}
          />
        )}
      </div>
    </FormProvider>
  );
};

export default ApplicationHealthInfo;

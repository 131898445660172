import { IKind } from '#types/painPartKinds';
import { Dispatch, SetStateAction } from 'react';
import ChipButtons from 'src/components/shared/ChipButtons';
import { HEALTH_INFO_REGISTERNAMES_PARSE } from '../constant';

interface Props {
  healthInfoType: string;
  setOption: Dispatch<SetStateAction<number | null>>;
  optionList?: IKind[];
}

const HealthInfoOptions = ({
  healthInfoType,
  setOption,
  optionList
}: Props) => {
  return (
    <>
      <div className="flex flex-col gap-12">
        <div className="flex gap-4">
          <div className="text-[#bcb8c3] text-base font-medium leading-[21px]">
            {HEALTH_INFO_REGISTERNAMES_PARSE[healthInfoType]}
          </div>
          <div className="text-[#938f9e] text-base font-normal leading-[21px]">
            (복수 선택 가능)
          </div>
        </div>
        <div className="flex flex-wrap gap-x-8 gap-y-12">
          {optionList
            ?.filter(value => value.name !== '직접 입력')
            ?.map((item: IKind) => (
              <ChipButtons
                key={item.id}
                options={item}
                setSelectedValue={setOption}
                buttonName={healthInfoType}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default HealthInfoOptions;

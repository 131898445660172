import { useGetUserQnaCommunityQuery } from '#api/community';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import CommunityFeeds from 'src/r_page/community/ArticlePage/feed';

type ThreadType = {
  id: string;
  gyms?: { id: string; name: string }[];
  writerId: string;
  category: string;
  content: string;
  sequence: number;
  title: string;
  createdAt: string;
  image: string | null;
  answerCount: number;
  name: string;
  startAthletic: string;
};

const MyPageQuestionList = () => {
  const { data: qnaCommunityData } = useGetUserQnaCommunityQuery();

  return (
    <PrevHeaderBarWithTitleTemplate title="내가 작성한 질문" prevRoute="../.">
      <div className="p-20 flex flex-col gap-20">
        {qnaCommunityData &&
          qnaCommunityData.map(threadData => (
            <CommunityFeeds<ThreadType>
              to={`/talk/qna/${threadData.id}`}
              thread={threadData}
            />
          ))}
      </div>
    </PrevHeaderBarWithTitleTemplate>
  );
};

export default MyPageQuestionList;

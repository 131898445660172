import { usePostQnaLikesMutaiton } from '#api/community';
import { ShareIcon } from '#assets/Icon/ShareIcon';
import { useShareTharedUrl } from '#utils/handleCopyClipBoard';
import { isUser } from '#utils/isUser';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import LikeIcon from 'src/r_page/community/CommunityDetail/QuestionComments/icon/LikeIcon';

interface Props {
  targetId?: string;
  isLike: boolean;
}

const QuestionBarShareAndLike = ({ targetId, isLike }: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const postQnaLikesMutation = usePostQnaLikesMutaiton();
  const copyThreadUrl = useShareTharedUrl();
  return (
    <div className="flex justify-between items-center px-20 py-8 text-[14px]">
      <button
        className="flex items-center gap-4 text-[#8E899F]"
        onClick={() =>
          copyThreadUrl(
            `https://fibud.kr/talk/qna/${id}`,
            '질문 답변 URL이 복사되었습니다.'
          )
        }
      >
        <ShareIcon /> 공유하기
      </button>
      <button
        className={`flex items-center gap-4 ${
          isLike ? 'text-[#9170F2]' : 'text-[#8E899F]'
        }`}
        onClick={() => {
          if (isUser()) {
            postQnaLikesMutation.mutate(
              { kind: 'ANSWER', targetId: targetId || '' },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries([
                    'get-qna-community-question-answer',
                    id
                  ]);
                }
              }
            );
          } else {
            navigate('/sign');
          }
        }}
      >
        <LikeIcon isLiked={isLike} /> 도움 됐어요
      </button>
    </div>
  );
};

export default QuestionBarShareAndLike;

import { ReactElement, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

import { BoxIcon, Text } from '#atoms';

import { HighlightChart } from 'src/components/organisims/HighlightChart';

import { ReactComponent as Diet } from '#assets/svg/diet.svg';
import { ReactComponent as BodyProfile } from '#assets/svg/body_profile.svg';
import { ReactComponent as CompetitionPreparation } from '#assets/svg/competition_preparation.svg';
import { ReactComponent as Endurance } from '#assets/svg/endurance.svg';
import { ReactComponent as MuscleStrength } from '#assets/svg/muscle_strength.svg';
import { ReactComponent as PainManagement } from '#assets/svg/pain_management.svg';
import { ReactComponent as Rehabilitation } from '#assets/svg/rehabilitation.svg';
import { ReactComponent as BodyCorrection } from '#assets/svg/body_correction.svg';

import {
  ChartWrap,
  Container,
  GoalBox,
  GoalCard,
  IconTextBox,
  ImgBox,
  IntroBox,
  PrevHeader
} from './styles';
import { useQuery } from '@tanstack/react-query';
import { axios } from '#api/axios';
import { Modal } from '#molecules';

export const PortfolioDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [detailImageView, setDetailImageView] = useState('');
  const [moreImages, setMoreImages] = useState({
    beforeImages: false,
    afterImages: false,
    clinicalImages: false
  });

  const { data: portfolioData } = useQuery({
    queryKey: ['portfolio-detail', id],
    queryFn: async () => await axios.get(`/clinical-data/get/detail/${id}`),
    enabled: !!id,
    select: data => data.data
  });

  if (!portfolioData) return null;

  return (
    <>
      <PrevHeader onClick={() => navigate(-1)}>
        <BoxIcon type="prev-arrow" boxColor="transparent" size={18} />
      </PrevHeader>
      <Container>
        {portfolioData.portfolioImage && (
          <ImgBox>
            <img src={portfolioData.portfolioImage} />
          </ImgBox>
        )}
        <IntroBox>
          <Text textStyle="t1" style={{ lineHeight: '3rem' }}>
            {portfolioData.title}
          </Text>
          <GoalBox>
            {portfolioData.criteria && (
              <GoalCard>
                <Text>목표</Text>
                <IconTextBox>
                  {CRITERIA_VALUES[portfolioData.criteria]?.icon}
                  <Text textColor="white">
                    {CRITERIA_VALUES[portfolioData.criteria]?.label}
                  </Text>
                </IconTextBox>
              </GoalCard>
            )}
            <GoalCard>
              <Text>횟수</Text>
              <Text textColor="white">16회</Text>
            </GoalCard>
          </GoalBox>
          <UserInfoBox>
            <div>
              <span>회원 정보</span>
              <p>
                {portfolioData.age} · {portfolioData.sex}
              </p>
            </div>
          </UserInfoBox>
          <InputArea>
            <InputLabel>소개글</InputLabel>
            <TextArea>{portfolioData.body}</TextArea>
          </InputArea>
        </IntroBox>

        <ChartWrap>
          <HighlightChart data={portfolioData} />
        </ChartWrap>
        {portfolioData.clinicalImages && portfolioData.clinicalImages.length ? (
          <div className="px-20 flex flex-col gap-24">
            <p className="text-[18px] font-700">수업 사진</p>
            <div className="flex flex-wrap items-center gap-4">
              {portfolioData.clinicalImages
                .slice(
                  0,
                  moreImages.clinicalImages
                    ? portfolioData.clinicalImages.length
                    : 9
                )
                .map((value: string, index: number) => {
                  return index < 8 || moreImages.clinicalImages ? (
                    <img
                      src={value}
                      key={value}
                      className="w-[calc(33%-4px)] object-cover aspect-square"
                      onClick={() =>
                        setDetailImageView(portfolioData.clinicalImages[index])
                      }
                    />
                  ) : (
                    <div
                      className="w-[calc(33%-4px)] relative aspect-square"
                      onClick={() =>
                        setMoreImages(prev => ({
                          ...prev,
                          clinicalImages: true
                        }))
                      }
                    >
                      <img
                        src={value}
                        key={value}
                        className="w-full object-cover aspect-square"
                      />
                      <p className="bg-[#121114c8] absolute text-[18px] top-0 left-0 text-center flex justify-center items-center  w-full h-full">
                        + {portfolioData.clinicalImages.length}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : null}
        <div className="px-20 py-32 my-32 border-y-10 border-solid border-[#0000001A]">
          <div className="flex flex-col gap-40">
            {portfolioData.beforeImages && portfolioData.beforeImages.length ? (
              <div className="flex flex-col gap-24">
                <p className="text-[18px] font-700">수업 전</p>
                <div className="flex flex-wrap items-center gap-4">
                  {portfolioData.beforeImages
                    .slice(
                      0,
                      moreImages.beforeImages
                        ? portfolioData.beforeImages.length
                        : 3
                    )
                    .map((value: string, index: number) => {
                      return index < 2 || moreImages.beforeImages ? (
                        <img
                          src={value}
                          key={value}
                          className="w-[calc(33%-4px)] object-cover aspect-square"
                          onClick={() =>
                            setDetailImageView(
                              portfolioData.beforeImages[index]
                            )
                          }
                        />
                      ) : (
                        <div
                          className="w-[calc(33%-4px)] relative aspect-square"
                          onClick={() =>
                            setMoreImages(prev => ({
                              ...prev,
                              beforeImages: true
                            }))
                          }
                        >
                          <img
                            src={value}
                            key={value}
                            className="w-full object-cover aspect-square"
                          />
                          <p className="bg-[#121114c8] absolute text-[18px] top-0 left-0 text-center flex justify-center items-center  w-full h-full">
                            + {portfolioData.beforeImages.length}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null}
            {portfolioData.afterImages && portfolioData.afterImages.length ? (
              <div className="flex flex-col gap-24">
                <p className="text-[18px] font-700">수업 후</p>
                <div className="flex flex-wrap items-center gap-4">
                  {portfolioData.afterImages
                    .slice(
                      0,
                      moreImages.afterImages
                        ? portfolioData.afterImages.length
                        : 3
                    )
                    .map((value: string, index: number) => {
                      return index < 2 || moreImages.afterImages ? (
                        <img
                          src={value}
                          key={value}
                          className="w-[calc(33%-4px)] object-cover aspect-square"
                          onClick={() =>
                            setDetailImageView(portfolioData.afterImages[index])
                          }
                        />
                      ) : (
                        <div
                          className="w-[calc(33%-4px)] relative aspect-square"
                          onClick={() =>
                            setMoreImages(prev => ({
                              ...prev,
                              afterImages: true
                            }))
                          }
                        >
                          <img
                            src={value}
                            key={value}
                            className="w-full object-cover aspect-square"
                          />
                          <p className="bg-[#121114c8] absolute text-[18px] top-0 left-0 text-center flex justify-center items-center  w-full h-full">
                            + {portfolioData.afterImages.length}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {detailImageView.length ? (
          <Modal
            width="90%"
            maxWidth="500px"
            onClose={() => setDetailImageView('')}
          >
            <p>사진 자세히 보기</p>
            <div className="flex items-center overflow-x-auto gap-4">
              <img
                src={detailImageView}
                className="w-full max-w-500 h-500 object-cover"
              />
            </div>
          </Modal>
        ) : null}
      </Container>
    </>
  );
};

export const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  position: relative;
`;

export const InputLabel = styled.label`
  font-size: 1.8rem;
  color: white;
`;

export const TextArea = styled.div`
  color: #c9c7d1;
  white-space: pre-line;
`;

const CRITERIA_VALUES: Record<string, { label: string; icon: ReactElement }> = {
  DIET: {
    label: '다이어트',
    icon: <Diet className="fill-[#7750E9]" />
  },
  REHABILITATION: {
    label: '재활치료',
    icon: <Rehabilitation className="fill-[#7750E9]" />
  },
  BODY_CORRECTION: {
    label: '체형교정',
    icon: <BodyCorrection className="fill-[#7750E9]" />
  },
  MUSCLE_STRENGTH: {
    label: '근력강화',
    icon: <MuscleStrength className="fill-[#7750E9]" />
  },
  ENDURANCE: {
    label: '체력증진',
    icon: <Endurance className="fill-[#7750E9]" />
  },
  PAIN_MANAGEMENT: {
    label: '통증관리',
    icon: <PainManagement className="fill-[#7750E9]" />
  },
  COMPETITION_PREPARATION: {
    label: '대회준비',
    icon: <CompetitionPreparation className="fill-[#7750E9]" />
  },
  BODY_PROFILE: {
    label: '바디프로필',
    icon: <BodyProfile className="fill-[#7750E9]" />
  }
};
const UserInfoBox = styled.div`
  padding: 1.6rem 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border-top: 1px solid #24222a;
  border-bottom: 1px solid #24222a;
  > div {
    display: flex;
    gap: 2.4rem;
    span {
      width: 8rem;
      color: #5c576b;
    }
    p {
      color: #c9c7d1;
    }
  }
`;

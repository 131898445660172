import { useQuery } from '@tanstack/react-query';

import { axios } from '#api/axios';

import { PrevHeaderBarWithTitleTemplate } from '#templates';

import CommunityFeeds from 'src/r_page/community/ArticlePage/feed';

export type ThreadType = {
  id: string;
  gyms?: { id: string; name: string }[];
  writerId: string;
  category: string;
  content: string;
  sequence: number;
  title: string;
  createdAt: string;
  image: string | null;
  answerCount: number;
  name: string;
  startAthletic: string;
};

const InformationCommunitySave = () => {
  const { data: threads } = useQuery({
    queryKey: ['get-information-community-save'],
    queryFn: async () =>
      await axios.get<Array<ThreadType>>('/consumer/exercise/bookmarks'),
    select: data => data.data
  });

  return (
    <PrevHeaderBarWithTitleTemplate title={'운동 가이드 - 저장된 글'}>
      <div className="flex flex-col gap-12 p-20 pt-0">
        {threads ? (
          <div className="flex flex-col gap-4 py-12">
            {threads.map(thread => (
              <CommunityFeeds<ThreadType>
                key={thread.id}
                to={`/talk/guide/${thread.sequence}`}
                thread={thread}
              />
            ))}
          </div>
        ) : null}
      </div>
    </PrevHeaderBarWithTitleTemplate>
  );
};

export default InformationCommunitySave;

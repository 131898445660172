import { useState } from 'react';
import { useLocation } from 'react-router';

import CommunitySecion from 'src/r_page/community/ArticlePage/Section';
import CommunityArticlePage from 'src/r_page/community/ArticlePage';
import WriteButton from 'src/r_page/community/WriteButton';

import * as S from 'src/r_page/community/style';
import MetaHelmet from '#utils/MetaHelmet';

const CommunityPage = () => {
  const { pathname } = useLocation();
  const [searchData, setSearchData] = useState('');

  const isQna = pathname.includes('qna') ? true : false;

  return (
    <>
      <MetaHelmet title={isQna ? '피벗 운동 질문 답변' : '피벗 운동 가이드'} />
      <div className="relative">
        <CommunitySecion
          route={pathname.replace('/talk/', '') ?? ''}
          isQna={isQna}
          setSearchData={setSearchData}
        />
        <S.Container>
          <CommunityArticlePage isQna={isQna} searchData={searchData} />
        </S.Container>
        {isQna && <WriteButton to="/talk/qna/write" />}
      </div>
    </>
  );
};

export default CommunityPage;

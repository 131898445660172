interface BMIProps {
  gender: string;
  bmi: number;
}

interface bodyFatProps {
  gender: string;
  bodyFat: number;
}

const STANDARD_BMI_MALE = 22;
const STANDARD_BMI_FEMALE = 21;
export class Calculator {
  static calculateBMI = ({ gender, bmi }: BMIProps) => {
    const standardBmi =
      gender === 'male' ? STANDARD_BMI_MALE : STANDARD_BMI_FEMALE;

    const bmiPercentage = (bmi / standardBmi) * 100;
    if (bmiPercentage < 85) {
      return { label: '표준이하', color: '#ff0000' };
    } else if (bmiPercentage >= 85 && bmiPercentage < 115) {
      return { label: '표준', color: '#00ff00' };
    } else if (bmiPercentage >= 115) {
      return { label: '표준이상', color: '#ff0000' };
    }
  };
  static calculateBodyFat = ({ gender, bodyFat }: bodyFatProps) => {
    if (gender === 'male') {
      if (bodyFat < 10) {
        return { label: '표준이하', color: '#ff0000' }; // 빨강
      } else if (bodyFat >= 10 && bodyFat < 20) {
        return { label: '표준', color: '#00ff00' }; // 초록
      } else {
        return { label: '표준이상', color: '#ff0000' }; // 빨강
      }
    } else {
      if (bodyFat < 18) {
        return { label: '표준이하', color: '#ff0000' }; // 빨강
      } else if (bodyFat >= 18 && bodyFat < 28) {
        return { label: '표준', color: '#00ff00' }; // 초록
      } else {
        return { label: '표준이상', color: '#ff0000' }; // 빨강
      }
    }
  };
}

import styled from 'styled-components';

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  padding: 0.8rem;
  margin-bottom: 2.4rem;
`;

export const StatusChangeLabel = styled.div`
  padding: 0.6rem 1.6rem;
  border: solid 0.1rem #2e2b36;
  border-radius: 1.6rem;
  font-size: 1.3rem;
`;

export const ChartTab = styled.div`
  width: 100%;
  display: flex;
  margin-top: 4rem;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
`;

export const Tab = styled.div<{ isSelected: boolean }>`
  border-radius: 0.8rem;
  text-align: center;
  padding: 0.8rem 1.2rem;
  font-size: 1.4rem;
  width: 33.3%;

  color: ${props => (props.isSelected ? 'var(--color-white)' : '#5c576b')};
  background-color: ${props => (props.isSelected ? '#2e2b36' : '#1f1e25')};
`;

export const IconBox = styled.div`
  width: 3.6rem;
  display: flex;
  justify-content: center;
  padding: 0.6rem;
`;

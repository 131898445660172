import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router';
import { axios } from '#api/axios';

import {
  CoachDetailApplyModal,
  PrevHeaderBarWithTitleTemplate
} from '#templates';
import { TabListViewer } from '#molecules';

import TrainerProfile from 'src/refactor_components/trainer-profile/trainer-profile';

import TrainerHome from 'src/r_page/trainer-profile/tab/home';
import TrainerInfo from 'src/r_page/trainer-profile/tab/info';
import TrainerActivities from 'src/r_page/trainer-profile/tab/activities';
import { useGetCoachReviews } from '#api/reviews';
import { CustomerReviews } from 'src/components/templates/CoachDetailContent/DetailInfo/CustomerReviews';
import { useGetCoachIdFromMyUrl } from '#api/my-url';
import MetaHelmet from '#utils/MetaHelmet';
import { Link } from 'react-router-dom';
import ChatIcon from '#assets/Icon/ChatIcon';
import { PATH } from '#const/path';
import { useRecoilState } from 'recoil';
import { $modalFamily, MODAL_TYPE } from '#states/atoms/modalState';
import { useChat } from '#providers/Chat';

const TrainerDetail = () => {
  const { myUrl } = useParams();
  const decodedMyUrl = decodeURIComponent(myUrl || '');
  const { data: id } = useGetCoachIdFromMyUrl(decodedMyUrl);
  // const setShowLoginModal = useSetRecoilState($modalFamily(MODAL_TYPE.LOGIN));

  const { data: trainerDetails } = useQuery({
    queryKey: ['get-trainer-account-detail', id],
    queryFn: async () => await axios.get(`accounts/coach/${id}`),
    select: data => data.data,
    enabled: !!id
  });

  const { data: questionDetails } = useQuery({
    queryKey: ['get-trainer-question-detail', id],
    queryFn: async () =>
      await axios.get(`consumer/exercise/coach/questions/${id}`),
    select: data => data.data,
    enabled: !!id
  });

  const { data: portfolioDetail } = useQuery({
    queryKey: ['get-trainer-portfolio', id],
    queryFn: async () => await axios.get(`clinical-data/list/${id}`),
    select: data => data.data,
    enabled: !!id
  });

  const { data: qnaQuestions } = useQuery({
    queryKey: ['get-trainer-qna-questions', id],
    queryFn: async () => await axios.get(`consumer/coach/questions/${id}`),
    select: data => data.data,
    enabled: !!id
  });

  const { data: reviewDetail } = useGetCoachReviews({ coachId: id ? id : '' });

  const hasReviews = reviewDetail?.total
    ? ['홈', '정보', '활동', '후기']
    : ['홈', '정보', '활동'];

  const { pathname } = useLocation();

  const [showApplyModal, setApplyModal] = useRecoilState(
    $modalFamily(MODAL_TYPE.APPLY)
  );

  const navigate = useNavigate();
  const { openChat } = useChat();

  const handleChatButtonClick = () => {
    const onSuccess = () => {
      navigate(PATH.CHAT_ROOM, {
        state: pathname
      });
    };
    const onFail = () => {
      // setShowLoginModal(true);
      navigate(PATH.SIGN, {
        state: { blockedPagePath: location.pathname + location.search }
      });
    };
    openChat(id ? id : '', onSuccess, onFail);
  };
  const handleApplyButtonClick = () => {
    setApplyModal(true);
  };

  if (!trainerDetails) return null;
  return (
    <>
      <MetaHelmet
        title={`${
          trainerDetails.account.name
        } 트레이너 - ${trainerDetails.gyms[0].address
          .split(' ')
          .slice(0, 2)
          .join(' ')} ${trainerDetails.gyms[0].name}`}
        description={`${trainerDetails.account.shortIntroduction}`}
        img={trainerDetails.account.profileImage[0]}
      />
      <PrevHeaderBarWithTitleTemplate
        defaultBackground
        title={`${trainerDetails.account.name} 트레이너`}
        prevRoute="../."
        rightContents={
          <Link to="/chat">
            <ChatIcon />
          </Link>
        }
      >
        <div className="pb-100">
          <TrainerProfile
            portfolioCount={portfolioDetail?.data?.length}
            address={trainerDetails.gyms}
            profile={trainerDetails.account}
          />
          <TabListViewer initTab={0}>
            <TabListViewer.List tabs={hasReviews} />
            <TabListViewer.Viewer
              tabs={[
                <TrainerHome
                  data={trainerDetails}
                  lecturesData={trainerDetails.lectures}
                  informationQuestion={questionDetails}
                  portfolio={portfolioDetail}
                  qnaQuestion={qnaQuestions}
                />,
                <TrainerInfo data={trainerDetails} id={id ? id : ''} />,
                <TrainerActivities />,
                <CustomerReviews coachId={id ? id : ''} />
              ]}
            />
          </TabListViewer>
        </div>
        <div className="fixed flex text-center bottom-0 max-w-939 w-full gap-17 bg-[#181818] py-8 px-20 z-30">
          <button
            className="bg-[#4D495A] rounded-8 text-[#C9C7D1] w-[calc(50%+10px)] py-16 min-w-[62px] px-29 z-3"
            onClick={handleChatButtonClick}
          >
            채팅 상담
          </button>
          <button
            type="button"
            onClick={() => {
              handleApplyButtonClick();
            }}
            className="bg-[#7750E9] rounded-8 text-[#EAE8ED] py-16 w-[calc(100%-62px)] z-1000"
          >
            수업 신청하기
          </button>
        </div>
      </PrevHeaderBarWithTitleTemplate>
      {showApplyModal && (
        <CoachDetailApplyModal
          setShowModal={setApplyModal}
          coachId={id ? id : ''}
        />
      )}
    </>
  );
};

export default TrainerDetail;

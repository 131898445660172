import { PATH } from '#const/path';
import AttendingLecture from '#providers/AttendLecture';
import { Chats } from '#providers/Chat';
import CustomerList from '#providers/CustomerList';
import { Dashboard } from '#providers/Dashboard';
import ToastProvider from '#providers/ToastProvider';
import UserInfo from '#providers/UserInfo';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import 'dayjs/locale/ko';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Navigate } from 'react-router';
import { RecoilRoot } from 'recoil';
import { StyleSheetManager } from 'styled-components';
import App from './App';
import './styles/color.css';
import './styles/index.css';
import './styles/textStyles.css';
import './styles/wrapper.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      onError: (err: unknown) => {
        const error = err as AxiosError;
        error.status === 401 && (
          <Navigate
            replace={true}
            to={PATH.SIGN}
            state={{ blockedPagePath: location.pathname + location.search }}
          />
        );
      }
    }
  }
});

const appWithProvider = (
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <UserInfo>
          <CustomerList>
            <Dashboard>
              <Chats>
                <AttendingLecture>
                  <StyleSheetManager disableCSSOMInjection={true}>
                    <HelmetProvider>
                      <App />
                    </HelmetProvider>
                  </StyleSheetManager>
                </AttendingLecture>
              </Chats>
            </Dashboard>
          </CustomerList>
        </UserInfo>
      </ToastProvider>
    </QueryClientProvider>
  </RecoilRoot>
);

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement);
root.render(appWithProvider);

const PortfolioIcon = ({ isSelect }: { isSelect: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_510_331"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path
          d="M17 4H20V20H17V4ZM5 14H8V20H5V14ZM11 9H14V20H11V9Z"
          fill={isSelect || '#A9A5B6'}
        />
      </mask>
      <g mask="url(#mask0_510_331)">
        <rect width="24" height="24" fill={isSelect || '#8E899F'} />
      </g>
    </svg>
  );
};

export default PortfolioIcon;

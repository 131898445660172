import { Helmet } from 'react-helmet-async';

function MetaHelmet({
  title,
  description,
  keywords,
  url,
  img
}: {
  title?: string;
  description?: string | string[];
  keywords?: string;
  url?: string;
  img?: string;
}) {
  const resolvedDescription = Array.isArray(description)
    ? description.join(', ')
    : description;

  return (
    <Helmet>
      <title>{title ?? metaDefaultProps.title}</title>
      <meta
        name="description"
        content={resolvedDescription ?? metaDefaultProps.description}
      />
      <meta property="og:title" content={title ?? metaDefaultProps.title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={img ?? metaDefaultProps.img} />
      <meta
        property="og:description"
        content={resolvedDescription ?? metaDefaultProps.description}
      />
      <meta property="og:url" content={url ?? metaDefaultProps.url} />
      <meta name="keywords" content={keywords ?? metaDefaultProps.keywords} />
      <meta property="og:site_name" content="피벗 Fibud" />
      <meta property="og:locale" content="ko_KR" />
      <meta
        property="al:web:url"
        content={(url ?? metaDefaultProps.url).replace(/\/$/, '')}
      />
    </Helmet>
  );
}

const metaDefaultProps = {
  title: '피벗',
  description: '숫자만 세주는 전문가 말고 진짜 전문가, PT는 피벗',
  keywords:
    '피벗, Fibud, 전문가, PT, 퍼스널 트레이닝, 다이어트, 체형교정, 재활, 바디프로필, 바디빌딩, 보디빌딩, 통증, 실버, 기능성, 검증, 실력',
  url: 'https://fibud.kr',
  img: 'https://fibud.kr/ms-icon-70x70.png' // 절대 경로로 변경
};

export default MetaHelmet;
